import React, {useEffect} from "react";
import TopHead from "../components/AutoMobile/topHead";
import CustomerEngagement from "../components/AutoMobile/customerEngagement";
import CustomerMapping from "../components/AutoMobile/customerMapping";
import SignUp from "../components/AutoMobile/signUp";
import { Helmet } from "react-helmet";
import whitelogo from "../images/favicon.png";

export default function AutoMobile() {
	useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
	return (
		<div>
			<Helmet>
                <title>Protect your consumers and brand from auto parts counterfeiting.</title>
                <meta name="description" content="Protect consumers and combat counterfeit auto-parts with Brandwise® authentication and deep trace solutions. Ensure product safety and traceability." />
                <meta name="keywords" content="Automobile,Auto-parts,Authentication." />
                {/* Open Graph tags for social media sharing */}
                <meta property="og:title" content="Protect your consumers and brand from auto parts counterfeiting." />
                <meta property="og:description" content="Protect consumers and combat counterfeit auto-parts with Brandwise® authentication and deep trace solutions. Ensure product safety and traceability." />
                <meta property="og:url" content="https://ciphercode.co/automobile" />
                <meta property="og:image" content={whitelogo} />
                <meta property="og:type" content="website" />
                {/* Twitter Card for better Twitter sharing */}
                <meta name="twitter:title" content="Protect your consumers and brand from auto parts counterfeiting." />
                <meta name="twitter:description" content="Protect consumers and combat counterfeit auto-parts with Brandwise® authentication and deep trace solutions. Ensure product safety and traceability." />
            </Helmet>
			<TopHead />
			<CustomerMapping />
			<CustomerEngagement />
			<SignUp />
		</div>
	);
}

