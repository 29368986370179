import React from "react";
import './tophead.css'



export default function TopHead() {
    return (
		<div style={{background: "linear-gradient(0.63deg, rgba(49, 87, 239, 0.1) 1.58%, rgba(49, 87, 239, 0) 97.39%)", textAlign: '-webkit-center'}}>
            <div className="container blogtophead">
                <h1>Careers</h1>
                <p className="px-6">Welcome! Ciphercode is a team of entreprenuerial, energetic and purposeful people with one thing in common – desire for self-improvement and contribution to the Company’s values and mission</p>
            </div>
        </div>
    )
}