import React, {useEffect} from "react";
import TopHead from "../components/HomeApplicants/topHead";
import CustomerEngagement from "../components/HomeApplicants/customerEngagement";
import CustomerMapping from "../components/HomeApplicants/customerMapping";
import Integrate from "../components/HomeApplicants/integrate";
import SignUp from "../components/HomeApplicants/signUp";
import img from "../images/homeApp_warranty.svg";
import { Helmet } from "react-helmet";
import whitelogo from "../images/favicon.png";


export default function HomeApplicants() {
	useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
	const customerMappingData = [
		{
			title : "GS1 Compliant Cryptoblock QR codes",
			description: "Brandwise is compliant with GS1 standards allowing our systems to efficiently communicate with your existing supply chain solutions leading to easy implementation and seamless billing at the authorized service centers."
		},
		{
			title : "REST APIs",
			description: "Connect Brandwise solutions to your existing systems using our REST APIs that seamlessly integrate into your supply chain and inventory management system."
		},
		{
			title : "Dynamic landing pages",
			description: "Use Brandwise QR codes to drive personalized marketing campaigns by dynamically linking them to marketing or engagement pages of your choice"
		}
	
	];
	return (
		<div>
			<Helmet>
                <title>Plug supply chain leakages and map distribution on Brandwise.</title>
                <meta name="description" content="Trace product origins globally with Brandwise® Deep TraceTechnology. Prevent cross-selling, boost customer engagement, and improve sales performance." />
                <meta name="keywords" content="Home appliances,Supply chain,Distrubution,Consumer mapping." />
                {/* Open Graph tags for social media sharing */}
                <meta property="og:title" content="Plug supply chain leakages and map distribution on Brandwise." />
                <meta property="og:description" content="Trace product origins globally with Brandwise® Deep TraceTechnology. Prevent cross-selling, boost customer engagement, and improve sales performance." />
                <meta property="og:url" content="https://ciphercode.co/homeappliances" />
                <meta property="og:image" content={whitelogo} />
                <meta property="og:type" content="website" />
                {/* Twitter Card for better Twitter sharing */}
                <meta name="twitter:title" content="Plug supply chain leakages and map distribution on Brandwise." />
                <meta name="twitter:description" content="Trace product origins globally with Brandwise® Deep TraceTechnology. Prevent cross-selling, boost customer engagement, and improve sales performance." />
            </Helmet>
			<TopHead />
			<CustomerMapping alldata={
			customerMappingData
			}
			mainHead="End-to-End Brand-Consumer Mapping" />
			<CustomerEngagement />
			<Integrate
			heading={"Digitize warranty registration"}
			subHeading={"Digitize your warranty registration process using Brandwise Cryptoblock QR codes driven engagement solution allowing consumers to register their product for warranty effortlessly, minimizing paperwork and build consumer intelligence models."}
			image={img}
			/>
			<SignUp />
		</div>
	);
}

