import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import img1 from '../../images/home7.webp';
import img2 from '../../images/home4.webp';
import img3 from '../../images/home2.webp';
import img4 from '../../images/home6.webp';
import './discover.css';

export default function discover() {
    return (
        <div className="discover">
            <div className="discover-content container" id="discoverContent">
                <div className="row g-4">
                    <h2>Discover How <span> Brandwise</span> transforms your Industry.</h2>
                    <div className="col-md-3 ">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img2} className='card-top-img' alt="retail_top" />
                            <Card.Body>
                                <Card.Title>RETAIL INDUSTRY</Card.Title>
                                <Card.Text>
                                    Using our innovative campaign
                                    solutions, a partner of Brandwise ...
                                </Card.Text>

                            </Card.Body>
                            <div class="card-footer ">
                                <a href="/retail"><Button variant="primary" >Know More</Button></a><br />
                            </div>


                        </Card>
                    </div>
                    <div className="col-md-3 ">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img1} className='card-top-img' alt="apparel_top" />
                            <Card.Body>
                                <Card.Title>APPAREL INDUSTRY</Card.Title>
                                <Card.Text>
                                    An Apparel Retail Partner was able to
                                    boost their store footfall using Brandwise
                                    ...
                                </Card.Text>

                            </Card.Body>
                            <div class="card-footer ">
                                <a href="/"><Button variant="primary" >Know More</Button></a><br />
                            </div>
                        </Card>
                    </div>
                    <div className="col-md-3 ">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img3} className='card-top-img' alt="footwear_top"/>
                            <Card.Body>
                                <Card.Title>FOOTWEAR INDUSTRY</Card.Title>
                                <Card.Text>
                                    Implemented Omnichannel Engagement
                                    strategies to boost  ...
                                </Card.Text>

                            </Card.Body>
                            <div class="card-footer ">
                                <a href="/footwear"><Button variant="primary" >Know More</Button></a><br />
                            </div>
                        </Card>
                    </div>
                    <div className="col-md-3 ">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img4} className='card-top-img' alt="personalcare_top"/>
                            <Card.Body>
                                <Card.Title>PERSONAL CARE INDUSTRY</Card.Title>
                                <Card.Text>
                                    Brandwise is a Flexible solution that can
                                    solve requirements ...
                                </Card.Text>

                            </Card.Body>
                            <div class="card-footer ">
                                <a href="/personalcare"><Button variant="primary" >Know More</Button></a><br />
                            </div>
                        </Card>
                    </div>
                </div><br /><br /><br />

            </div>
        </div>
    )
}
