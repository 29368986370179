import React from "react";
import './faq.css';
import img1 from "../../images/faq_img-transformed.webp";


export default function Solution() {
    const faqs = [
        { question: "What is QSeal and how does it benefit my brand?", answer: "" },
        { question: "Is QSeal Compliant with Global Regulations?", answer: "" },
        { question: "Can QSeal Detect tampering?", answer: "" },
        { question: "How does QSeal help prevent/fight counterfeits?", answer: "" },
        { question: "What kind of products can QSeal authenticate?", answer: "" },
        { question: "What Technology is QSeal Based off?", answer: "" },
    ];
    return (
        <div className="container qreachknowmore-faqs">
            <h2>FAQs</h2>
            <div className="faqs-content">
                <div className="row">
                    <div className="col s12 m4">
                        <h3>Still Have<br />
                            Questions about<br />
                            QSeal?</h3>
                        <img
                            src={img1}
                            alt=""
                            className="faq-image"
                        />
                    </div>
                    <div className="col s12 m5">
                    {faqs.map((faq, index) => (
                            <div key={index} className="faq-item">
                                <div className="faq-question">
                                    {faq.question}
                                    <div className="dropdown-icon-wrapper">
                                        <span className="dropdown-icon">▼</span>
                                    </div>
                                </div>
                                <div className="faq-answer">{faq.answer}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            
        </div>
        

    );
}