import React from 'react';
import Marquee from "react-fast-marquee";
import img2 from "../../images/HomeBanner.webp";
import img3 from "../../images/Guess.svg";
import img5 from "../../images/shycocan.svg";
import img7 from "../../images/Demoza.svg";
import img8 from "../../images/cii.svg";
import img9 from "../../images/sofas & More.svg";
import img10 from "../../images/ABFRL.svg";
import img11 from "../../images/mensa_black.png";
import img1 from "../../images/G2_ image.png";
import img12 from "../../images/nascom.png";
import img13 from "../../images/denis_lingo.webp";
import img14 from "../../images/livp.webp";
import img15 from "../../images/unnamed.webp";
import { Link } from "react-router-dom";

import './top-head.css';

function TopHead() {
  return (
    <>
      <header className="container top-head">
        <div className="row">
          <div className="col-md-5 order-1 order-md-2">
            <div className="top-content">
              <img src={img1} alt="G2 Rating" style={{ height: '50px' }}/>
              <img src={img12} alt="NASSCOM" style={{ height: '50px' }} /><br /><br />
              <h1>Transform your Brand:<span className="myBlue"> Digitize, Engage & Grow </span> with <span className="myBlue">Brandwise </span></h1>
              <p style={{ fontSize: "14px" }}>One-stop platform to turn your physical products into powerful
                digital assets, enabling deeper customer connections and
                data-driven growth to become an Industry leader</p>
              <hr />
              <div className="row">
                <section className="col-md-4 col-12 digital">
                  <h4>50,000+</h4>
                  <p style={{ fontSize: "12px" }}>Products Digitized</p>
                </section>
                <section className="col-md-4 col-12 digital">
                  <h4>3 Million+</h4>
                  <p style={{ fontSize: "12px" }}>Customers Engaged</p>
                </section>
                <section className="col-md-4 col-12 digital">
                  <h4>70%*</h4>
                  <p style={{ fontSize: "12px" }}>Higher Store Sales</p>
                </section>
              </div>
            </div>
            <Link to="/schedule_demo" rel="noreferrer">
              <button className="btn btn-BlueBg">Get a demo</button>
            </Link>
            <Link to="/product" rel="noreferrer">
              <button className="btn btn-borderBlue">Know More</button>
            </Link>
          </div>
          <div className="col-md-1 order-2 order-md-2"></div>
          <div className="col-md-6 order-2 order-md-2">
            <img src={img2} alt="Customer engaging with exciting marketing campaigns while companies are 
            able to gather insightful data for higher ROI" title="Customer engagement and secure insights gathering." style={{ width: '108%' }} />
          </div>
        </div>
      </header>
      <section className="marquee-slider">
        <p>Meet the top Brands using Brandwise</p><br />
        <Marquee speed={100} delay={0} pauseOnHover={true}>
          <div className="image_wrapper">
            <img src={img3} alt="Guess logo" />
          </div>
          <div className="image_wrapper">
            <img src={img5} alt="Shycocan logo" />
          </div>
          <div className="image_wrapper">
            <img src={img7} alt="Demoza logo" />
          </div>
          <div className="image_wrapper">
            <img src={img8} alt="CII logo" />
          </div>
          <div className="image_wrapper">
            <img src={img9} alt="Sofas & More logo" />
          </div>
          <div className="image_wrapper">
            <img src={img10} alt="ABFRL logo" />
          </div>
          <div className="image_wrapper">
            <img src={img11} alt="Mensa logo" />
          </div>
          <div className="image_wrapper">
            <img src={img13} alt="dennis_lingo" />
          </div>
          <div className="image_wrapper">
            <img src={img14} alt="livp_img" />
          </div>
          <div className="image_wrapper">
            <img src={img15} alt="elemage_img" />
          </div>
        </Marquee>
      </section><br /><br /><br />
    </>
  );
}

export default TopHead;
