import React from "react";
import img2 from  '../../images/product_scan.png';
import './solution.css';
import {Link} from "react-router-dom";

export default function Solution() {
	return (
        <div style={{backgroundColor:'#060918'}}>
        <div className="container position-value">
            <div className="row">
                    <div className="col-md-6">
                        <div className="position-content">
                            <strong>Additional Value</strong>
                            <h2>Compatibility to public <br></br>blockchain & web 3 <br></br>applications</h2>
                            <p>Compatible to future trends and promising <br></br>technologies, such as turning objects to <br></br> potential NFT tokens was an adeed value.</p>
                            <br></br>
                            <span>Build your first QR Code now</span>
                            <hr style={{backgroundColor:'#fff'}}></hr>
                            <Link to="/contactus" rel=""><button className="para-content">Know more</button></Link>
                            <div></div>
                        </div>
                    </div>
                    <div className="col-md-6">
                    <div className="position-imagefix row">
                         <img src={img2} alt="Personal Care products with Brandwise QR codes 
                         designed aesthetically onto the packaging" title="Personal care products featuring Brandwise QR codes on packaging." />
                    </div>
                    </div>
            </div>
        </div>
        </div>
    );
}