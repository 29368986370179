import React from "react";
import "../../css/style.css";
import "../../css/responsive.css";
import darklogo from "../../images/Group 528.png";
import whitelogo from "../../images/whitelogo.png";
import { Link } from "react-router-dom";


function Navbar() {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <Link to="/" className="navbar-brand"><img alt="brandwise" src={window.location.pathname === "/product" ? darklogo : whitelogo} /></Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon">
              <svg width="25" height="20" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 1H24.5" stroke="white" strokeWidth="2" />
                <path d="M0 9H24.5" stroke="white" strokeWidth="2" />
                <path d="M0 17H24.5" stroke="white" strokeWidth="2" />
              </svg>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item"><Link className="nav-link" to="/product" aria-expanded="false">
                <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Product</span></Link>
              </li>
              <li className="nav-item"><Link className="nav-link" aria-current="page" to="/blog">
                <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Blog</span></Link>
              </li>
              <li className="nav-item"><Link className="nav-link" aria-current="page" to="/usecases">
                <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Use Cases</span></Link>
              </li>
              <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Resources</a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                 <li><a className="dropdown-item" href="/encryption" >
                    <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Encryption</span></a>
                  </li>
                  <li><a className="dropdown-item" href="/resources">
                    <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Cryptoblock</span></a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Company</a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><Link className="dropdown-item " to="/aboutus">
                    <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">About Us</span></Link>
                  </li>
                  <li><Link className="dropdown-item" to="/contactus" >
                    <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Contact US</span></Link>
                  </li>
                  <li><Link className="dropdown-item" to="/career" >
                    <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Careers</span></Link>
                  </li>
                  <li><Link className="dropdown-item" to="/qrcode/url" >
                    <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Cryptoblock QR</span></Link>
                  </li>
                  <li><Link className="dropdown-item" to="/partnershipprogram" >
                    <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Partnership Program</span></Link>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="navButtons navContact">
              <Link to="/contactus" style={{color:'black'}}><span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" style={{marginLeft:"10px"}} >Contact Us</span></Link>
              <p>+91-9741111594</p>
            </div>
            <div className="d-flex align-items-center navButtons">
              <Link to="/schedule_demo">
                <button className="btn btn-BlueBg">
                  <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                    Get Demo
                  </span>
                </button>
              </Link>
              <Link to="/signup_create" style={{ marginLeft: 10}}>
                <button className="btn btn-BlueBg">
                  <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                    Get Started
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}
export default Navbar;