import React, {useEffect} from "react";
import TopHead from "../components/ArticlePost/topHead";
import PostContent from "../components/ArticlePost/postcontent";
import { Helmet } from "react-helmet";
import whitelogo from "../images/favicon.png";

function Blog() {
	useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
	return (
		<div>
			<Helmet>
                <title>The Power of Encryption: Protecting Your Data in Mobile Calls, Online Shopping, and More.</title>
                <meta name="description" content="Discover the vital role of encryption in securing modern transactions, from mobile calls to online shopping. Learn how it protects your data and privacy." />
                <meta name="keywords" content="Encryption,Security,Digital protection." />
                {/* Open Graph tags for social media sharing */}
                <meta property="og:title" content="The Power of Encryption: Protecting Your Data in Mobile Calls, Online Shopping, and More." />
                <meta property="og:description" content="Discover the vital role of encryption in securing modern transactions, from mobile calls to online shopping. Learn how it protects your data and privacy." />
                <meta property="og:url" content="https://ciphercode.co/encryption" />
                <meta property="og:image" content={whitelogo} />
                <meta property="og:type" content="website" />
                {/* Twitter Card for better Twitter sharing */}
                <meta name="twitter:title" content="The Power of Encryption: Protecting Your Data in Mobile Calls, Online Shopping, and More." />
                <meta name="twitter:description" content="Discover the vital role of encryption in securing modern transactions, from mobile calls to online shopping. Learn how it protects your data and privacy." />
            </Helmet>
			<TopHead />
			<PostContent />
		</div>
	);
}
export default Blog;
