import React from 'react';
import img1 from "../../images/retail2.webp";
import img2 from "../../images/retail3.webp";
import img3 from "../../images/retail4.webp";
import img4 from "../../images/retail5.webp";
import './roi.css';

export default function Roi() {
	return (
		<div className="container roi-content" id="ROIcontent">
        <div className="row">
            <div className="col-md-5 order-md-2">
                <div className="retail-content2">
                  <h2>Drive <span>Higher ROI</span></h2>
                  <p>In the ever-evolving landscape of consumer
                     behaviors and transforming technologies. Leverage
                     the diverse range of tools on Brandwise.</p>
                <div className='list-content'>
                   <ul>
                      <li>Increased in store walk-ins by *8x</li>
                      <li>*3x Increase in Repeat Purchases</li>
                      <li>*40% Decrease in Customer Acquisition Cost</li>
                      <li>Leverage dynamic coupons – *3x higher conversions</li>
                      
                   </ul>
                   </div>
                </div>
            </div>
            <div className="col-md-1 order-md-2"></div>
            <div className="col-md-6 order-md-2 " style={{textAlign:"center"}}>
                <img src={img1} alt="Retail Brands attract Customers to purchase products using Brandwise Marketing 
                campaigns for Increase walkins, purchases etc." style={{width:'70%',height:"90%"}} title="Retail brands attracting customers with Brandwise campaigns." />   
            </div>
            </div><br></br><br></br>
            <div className="row">
                <div className="col-12 col-md-6 order-2 order-md-1" style={{textAlign:"center"}} >
                    <img src={img2} alt="Companies gather first party data from customers to understand their audiences 
                    better to segmentize and target effectively." style={{width:'80%',height:"90%"}} title="Companies gathering first-party data for better audience targeting." />
                </div>
                <div className="col-12 col-md-1 order-1 order-md-2"></div>
                <div className="col-12 col-md-5 order-1 order-md-2">
                    <div className="retail-content2" >
                    <h2>First Party Data, The
                    <span> Currency of Trust</span></h2>
                        <p>In the modern age, knowing your end users isn’t just a
                           competitive advantage, it’s the foundation of
                           customer-centricity, brand loyalty and business
                           growth. Smart businesses are shifting from KYC to
                           UYC-Understand Your Customers.</p>
                        <div className='list-content'>
                        <ul>
                            <li>Advanced Segmentation, automated re-targeting</li>
                            <li>Privacy First approach with global compliance</li>
                            <li>Realtime insights, accurate profiling</li>
                            <li>Customer-Centric experience delivery to customers</li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div><br></br><br></br>
            <div className="row">
            <div className="col-md-5 order-md-2">
                <div className="retail-content2">
                  <h2><span>Versatile</span> Promotion Channels</h2>
                  
                        <p>Growing businesses cater diverse customers with
                           wider preferences, its vital to reach them on the
                           suitable channel.</p>
                        <div className='list-content'>  
                        <ul>
                            <li>16+ Channels</li>
                            <li>BYC (Build Your own Campaigns)</li>
                            <li>Online, Offline and Omnichannel Campaigns</li>        
                        </ul> 
                        </div>  
                </div>
            </div>
            <div className="col-md-1 order-md-2"></div>
            <div className="col-md-6 order-md-2" style={{textAlign:"center"}}>
            <img src={img3} alt="Customer engaging with omnichannel marketing across digital and physical platforms for enhanced 
            interaction and seamless brand experience" style={{width:'80%',height:"100%"}} title="Customer engaging with seamless omnichannel marketing." />
            </div>
            </div><br></br><br></br>
            <div className="row">
                <div className="col-12 col-md-6 order-2 order-md-2" style={{textAlign:"center"}}>
                    <img src={img4} alt="Woman surprised by the unique customer experiences and are looking forward to come back for 
                    more enabling brands to build loyalty" style={{width:'80%',height:"90%"}} title="Woman excited by unique experiences, fostering brand loyalty." />
                </div>
                <div className="col-12 col-md-1 order-1 order-md-2"></div>
                <div className="col-12 col-md-5 order-1 order-md-2">
                    <div className="retail-content2">
                    <h2>Foster <span> Loyalty</span> & <span> Retention</span> </h2>
                        <p>Capture streams of data to retarget customers effectively
                           with personalized suggestions, experiences and more.<br/>
                           Turn customers into partners, empower them to earn
                           dynamic rewards, invoke interactions, build communities
                           and transform loyalty into advocacy.</p>
                     <div className='list-content'>
                        <ul>
                            <li>Advanced Segmentation, automated re-targeting</li>
                            <li>Privacy first approach with global compliance</li>
                            <li>Realtime insights, accurate profiling</li>
                            <li>Customer-centric experience delivery to
                            customers</li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
            <div id="useContent"></div>
        </div>
    )
}