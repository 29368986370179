import React from "react";
import img27 from "../../images/cryp_bg.gif";
import './tophead.css'
import whitelogo from "../../images/favicon.png";
import { Helmet } from "react-helmet";




function TopHead(){
  const myStyle={
    backgroundImage: `url(${img27})` ,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    };
    return (
      <>
       <Helmet>
        <title>Customer Engagement and Digitization Leading Solutions</title>
        <meta name="description" content="Cutting-edge solutions for customer engagement, product digitization and authentication. Boost brand loyalty, trust and sales with custom QR campaigns." />
        <meta name="keywords" content="Ciphercode, Brandwise, Customer Engagement, Product Digitzation, Authentication, Brand Loyalty" />
        <meta property="og:title" content="Customer Engagement and Digitization Leading Solutions" />
        <meta property="og:description" content="Cutting-edge solutions for customer engagement, product digitization and authentication. Boost brand loyalty, trust and sales with custom QR campaigns." />
        <meta property="og:url" content="https://ciphercode.co/qrcode/url/" />
        <meta property="og:image" content={whitelogo} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Customer Engagement and Digitization Leading Solutions" />
        <meta name="twitter:description" content="Cutting-edge solutions for customer engagement, product digitization and authentication. Boost brand loyalty, trust and sales with custom QR campaigns." />
      </Helmet>
      <div style={{background: "linear-gradient(0.63deg, rgba(49, 87, 239, 0.1) 1.58%, rgba(49, 87, 239, 0) 97.39%)", textAlign: '-webkit-center'}}>
			<div className="tophead" style={myStyle}>
				<div className="row pb-5">
					<div className="container text-center">
						<h1>Create your Cryptoblock QR here</h1>
						<p className="px-6">Experience power of dynamic cryptoblock QR</p>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
export default TopHead;