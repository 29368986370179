import React from "react";
import './tophead.css'
import img1 from "../../images/footwear_banner.webp";
import img2 from "../../images/QReach-Hero-Image.png";

export default function TopHead() {
    return (
        <>
        <div><img className="cover-image" src={img1} alt="cover_pic" />
            <div className="container qreachknowmore-tophead">
                <div className="qreach-content">
                    <div className="row">
                        <div className="col-md-5 order-2 order-md-2">
                            <h1>Build Stronger<span> Relationships</span> with your Customers</h1>
                            <p>A cross-channel customer engagement platform built for
                                marketers and product owners who value agility over
                                cumbersome complexity. We help consumer brands adapt
                                quickly to evolving customer expectations through real-time
                                insights and personalized cross-channel communications.
                            </p>
                            <a href="/schedule_demo"><button type="button">GET DEMO</button></a>
                        </div>
                        <div className="col-md-2 order-2 order-md-2"></div>
                        <div className="col-md-5 order-1 order-md-2 " style={{ textAlign: "right" }}>
                            <img src={img2} alt="Man receiving omnichannel alerts and suggestions from whatsapp, email, messages etc to purchase clothes he is interested in" style={{ width: "100%", height: "100%", marginTop:'5px' }} />
                        </div>
                    </div>
                </div>
                <br></br>
            </div>
            </div>
        </>
    );
}