import React, {useEffect} from "react";
import TopHead from "../components/Resources/topHead";
import CryptoHelps from "../components/Resources/cryptoHelps";
import ThingsCanDo from "../components/Resources/thingsCanDo";
import { Helmet } from "react-helmet";
import whitelogo from "../images/favicon.png";


function Resources() {
	useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
	return (
		<div>
			<Helmet>
                <title>Boost Product Trust with Cryptoblock powered QR Codes: Traceability, Authenticity, and Consumer Insights.</title>
                <meta name="description" content="Discover how our permissioned Blockchain and cryptographic QR codes enhance product authenticity and traceability, ensuring secure ownership and real-time consumer insights." />
                <meta name="keywords" content="Cryptoblock,Advanced authentication,QR Codes." />
                {/* Open Graph tags for social media sharing */}
                <meta property="og:title" content="Boost Product Trust with Cryptoblock powered QR Codes: Traceability, Authenticity, and Consumer Insights." />
                <meta property="og:description" content="Discover how our permissioned Blockchain and cryptographic QR codes enhance product authenticity and traceability, ensuring secure ownership and real-time consumer insights." />
                <meta property="og:url" content="https://ciphercode.co/resources" />
                <meta property="og:image" content={whitelogo} />
                <meta property="og:type" content="website" />
                {/* Twitter Card for better Twitter sharing */}
                <meta name="twitter:title" content="Boost Product Trust with Cryptoblock powered QR Codes: Traceability, Authenticity, and Consumer Insights." />
                <meta name="twitter:description" content="Discover how our permissioned Blockchain and cryptographic QR codes enhance product authenticity and traceability, ensuring secure ownership and real-time consumer insights." />
            </Helmet>
			<TopHead></TopHead>
			<CryptoHelps></CryptoHelps>
			<ThingsCanDo></ThingsCanDo>
		</div>
	);
}
export default Resources;
