import React from "react";
import img from "../../images/Blogpost Digitization.svg";
import img3 from "../../images/Blogpost Counterfeit.svg";
import img4 from "../../images/Wine_NFT.svg";
import img5 from "../../images/Optimizing-how-first-party-data-is-collected-1.jpg";
import img6 from "../../images/future1.png";
import img7 from "../../images/blogseven2.webp";
import img8 from "../../images/blogeight2.webp";
import img9 from "../../images/med_img2.webp";
import img2 from "../../images/blog-cryptoblock.svg";
import img10 from "../../images/ctp1.webp";
import img11 from "../../images/unlocking_future1.webp";
import PostCard from "./postCard";

export default function newBlog() {
	return (
		<div className="container">
			<h1 className="fw-bold pb-4 py-5">New Arrivals</h1>
			<div className="row">
			<div className="col-12 col-md-4 order-md-2">
					<PostCard img={img11} title="Unlocking the Future of Trust" description="Discover how Brandwise redefines trust with AI-driven personalization, data unification, and unmatched security, enabling brands to foster loyalty and create seamless, meaningful customer experiences across all touchpoints." url="/unlocking-the-future-of-trust" />
				</div>
			    <div className="col-12 col-md-4 order-md-2">
					<PostCard img={img10} title="Customer Trust Platform, Why its Important?" description="Discover why building a Customer Trust Platform is crucial for luxury brands to thrive. Learn how unified intelligence, seamless experiences, and AI-driven personalization redefine loyalty and create lasting connections." url="/ctp" />
				</div>
				<div className="col-12 col-md-4 order-md-2">
					<PostCard img={img9} title="Safety First: How QR Codes are Revolutionizing Pharma Industry" description="Discover how the CDSCO’s expanded mandate for QR codes on pharmaceutical packaging is tackling counterfeit medicines in India, enhancing drug safety, and ensuring product authenticity." url="/qrcodes_are_revolutionizing_pharma_industry" />
				</div>	
			</div><br/>
			<div className="row">
			    <div className="col-12 col-md-4 order-md-2">
					<PostCard img={img7} title="How Brands are Shifting from Vanity Metrics to True Revenue Metrics" description="Brands are shifting from vanity metrics to revenue-based metrics, prioritizing conversions, generated revenue, and true ROI for growth." url="/vanity_metrics_to_revenue_metrics" />
				</div>
				<div className="col-12 col-md-4 order-md-2">
					<PostCard img={img8} title="The Shift from Information to Intelligence:" description="Luxury brands use AI and Personalization to meet evolving consumer expectations and ensure sustainability in order to embrace the shift from information to intelligence" url="/information_to_intelligence" />
				</div>
				<div className="col-12 col-md-4 order-md-2">
				    <PostCard img={img6} title="Navigating The future:Anticipated Challenges & Opportunities for Brands in the near Future" description="Explore Challenges, Opportunites & Strategies for brands to adapt to evolving customer preferences, technology and market dynamics for future success." url="/navigating_the_future"  />
				</div>	
			</div><br/>
			<div className="row">
			
				<div className="col-12 col-md-4 order-md-2">
				    <PostCard img={img5} title="Navigating The Cookie-less Future: First-Party Data" description="In the evolving landscape of digital advertising, the cessation of third-party cookies in 2024 marks a transformative shift." url="/first_party"  />
				</div>
				<div className="col-12 col-md-4 order-md-2">
					<PostCard img={img4} title="How The NFT Staking Is Transforming The Wine Industry?" description="A Non-Fungible Token — NFT — is a unique digital asset representing ownership of a real-world item like an art, music, photo, physical product.."  url="/nft_staking" />
				</div>
				<div className="col-12 col-md-4 order-md-2">
					<PostCard img={img3} title="How Cryptoblock QR codes can prevent the counterfeits?" description="People buy luxury goods for variety of emotional satisfactions; such strong feelings are closely associated with the authenticity.." url="/cryptoblock_anti-counterfeit" />
				</div>
				
			</div><br />
			<div className="row">
			
				<div className="col-12 col-md-4 order-md-2">
					<PostCard img={img2} title="What makes Cryptoblock QRs unique & advanced?" description="Quick Response Code- is a two-dimensional bar code created and patented by Japanese corporation Denso-Wave in 1994.." url="/cryptoblock_qr" />
				</div>
				<div className="col-12 col-md-4 order-md-2">
					<PostCard img={img} title="Product digitalization - How it helps transform the world." description="Connected consumer and digital savvy markets demanding new age experiences, smart brands leveraging the technology.."  url="/product_digitization" />
				</div>				
			</div><br/>
		
		</div>
	);
}
