import React from "react";
import './tophead.css'
import img1 from "../../images/footwear_banner.webp";
import img2 from "../../images/Footwear 1.webp";

export default function TopHead() {
	return (
		<>
        <div>
           <img className="cover-image" src={img1} loading="lazy" alt="cover_pic"/>
        </div>
        <div className="container footwear-tophead">
        <div className="footwear-content">
            <div className="row">
                <div className="col-md-6 order-2 order-md-2 ">
                <h1>Step Up Your Game: Sole-fully <span> Attract</span> More Customers </h1>
                <p>Unlock the full potential of your marketing
                    campaigns with Brandwise’s powerful
                    Engagement Tools. Collect, analyze, and utilize
                    customer data like never before. Safeguard your
                    customers with secure authentication of your
                    products.
                </p>
                <br></br>
                <div className="row">
                    <div className="col">
                        <input type="text" placeholder="Full Name"/>
                    </div>
                    <div className="col">
                        <input type="text" placeholder="Business Email"/>
                    </div>
                </div>
                <button type="button">Connect with an expert</button>
                </div>
                <div className="col-md-6 order-1 order-md-2 " style={{textAlign:"right"}}>
                    <img src={img2} alt="Customer using Brandwise QSeal to authenticate the shoe by simply 
                    scanning a QR Code for a hassle free experience" loading="lazy" style={{width:"100%",height:"100%"}} title="Customer receiving omnichannel purchase suggestions."/>
                </div>
            </div>
        </div>
          <br></br>
          <div className="highlight-content" id="challengesButton">
            <div className="tab-container">
                <a href="#challengesContent" className="tab"><button>Challenges</button></a>
                <span className="separator"></span>
                <a href="#boostContent" className="tab"><button>What You Get</button></a>
                <span className="separator"></span>
                <a href="#useContent" className="tab"><button>How to Use </button></a>
                <span className="separator"></span>
                <a href="#feedbackContent" className="tab"><button>Testimonies</button></a>
             </div>
        </div><br/><br/>
		</div>       
        </>
	);
}