import React from "react";
import Navbar from "./components/Navbar/navbar";
import Home from "./pages/home";
import Resources from "./pages/resources";
import Blog from "./pages/blog";
import BlogPost from "./pages/blogpost";
import BlogPost2 from "./pages/blogpost2";
import BlogPost3 from "./pages/blogpost3";
import BlogPost4 from "./pages/blogpost4";
import BlogPost5 from "./pages/blogpost5";
import BlogPost6 from "./pages/blogpost6";
import BlogPost7 from "./pages/blogpost7";
import BlogPost8 from "./pages/blogpost8";
import BlogPost9 from "./pages/blogpost9";
import BlogPost10 from "./pages/blogpost10";
import BlogPost11 from "./pages/blogpost11";
import Partnership from "./pages/partnership";
import ArticlePost from "./pages/articlepost";
import Product from "./pages/product";
import Contactus from "./pages/contactus";
import About from "./pages/aboutus";
import LocationQR from "./components/QRCode/location_qr";
import WebformQR from "./components/QRCode/webforms";
import QReportPage from "./pages/qReport";
import FooterNewInfra from "./components/ContactUs/footerNewInfra";
import URlQR from "./components/QRCode/url_qr";
import WhatsAppQR from "./components/QRCode/whatsapp";
import SocialMedia from "./components/QRCode/social_media";
import PDFQR from "./components/QRCode/file_qr";
import CampaignQR from "./components/QRCode/campaign";
import ShortURL from "./components/QRCode/shorturl";
import TermsCondition from "./pages/terms&conditions";
import {Route, Routes} from 'react-router-dom';
import HomeApplicants from "./pages/homeapplicants";
import AutoMobile from "./pages/automobile";
import CareerPage from "./pages/careers";
import PersonalCarePage from "./pages/personalcare";
import Sitemap from "./pages/sitemap";
import BrandSom from "./pages/bransdsom";
import Packaging from "./pages/packaging";
import Footwear from "./pages/footwear";
import ScheduleForm from "./components/Login/scheduleDemo";
import CookieConsent from "react-cookie-consent";
import { BsWhatsapp} from "react-icons/bs";
import NotFound from "./pages/404page";
import Retail from "./pages/retail";
import Onboarding from "./pages/onboarding";
import QreachKnowMore from "./pages/qreachknowmore";
import UseCase from "./pages/usecase";
import QsealKnowMore from "./pages/qsealknowmore";


function App() {
  return (
    <div className="App">
      <div className="app-container">
        <Navbar />
         <Routes>
          <Route path="/" element={<Home></Home>}></Route> 
          <Route path="/resources" element={<Resources></Resources>}></Route>
          <Route path="/blog" element={<Blog></Blog>}></Route>
          <Route path="/product_digitization" element={<BlogPost></BlogPost>}></Route>
          <Route path="/cryptoblock_qr" element={<BlogPost2></BlogPost2>}></Route>
          <Route path="/cryptoblock_anti-counterfeit" element={<BlogPost3></BlogPost3>}></Route>
          <Route path="/encryption" element={<ArticlePost></ArticlePost>}></Route>
          <Route path="/nft_staking" element={<BlogPost4></BlogPost4>}></Route>
          <Route path="/first_party" element={<BlogPost5></BlogPost5>}></Route>
          <Route path="/navigating_the_future" element={<BlogPost6></BlogPost6>}></Route>
          <Route path="/vanity_metrics_to_revenue_metrics" element={<BlogPost7></BlogPost7>}></Route>
          <Route path="/information_to_intelligence" element={<BlogPost8></BlogPost8>}></Route>
          <Route path="/qrcodes_are_revolutionizing_pharma_industry" element={<BlogPost9></BlogPost9>}></Route>
          <Route path="/ctp" element={<BlogPost10></BlogPost10>}></Route>
          <Route path="/unlocking-the-future-of-trust" element={<BlogPost11></BlogPost11>}></Route>
          <Route path="/product" element={<Product></Product>}></Route>
          <Route path="/contactus" element={<Contactus></Contactus>}></Route>
          <Route path="/aboutus" element={<About></About>}></Route>
          <Route path="/homeappliances" element={<HomeApplicants></HomeApplicants>}></Route>
          <Route path="/automobile" element={<AutoMobile></AutoMobile>}></Route>
          <Route path="/personalcare" element={<PersonalCarePage></PersonalCarePage>}></Route>
          <Route path="/partnershipprogram" element={<Partnership></Partnership>}></Route>
          <Route path="/usecases" element={<UseCase></UseCase>}></Route>
          <Route path ="/product/qReport" element={<QReportPage></QReportPage>}></Route>
          <Route path="/career" element={<CareerPage></CareerPage>}></Route>
          <Route path="/privacy" element={<TermsCondition></TermsCondition>}></Route>
          <Route path="/footwear" element={<Footwear></Footwear>}></Route>
          <Route path="/retail" element={<Retail></Retail>}></Route>
          <Route path="/packaging" element={<Packaging></Packaging>}></Route>
          <Route path="/qrcode/url" element={<URlQR />}></Route>
          <Route path="/qrcode/whatsapp" element={<WhatsAppQR />}></Route>
          <Route path="/qrcode/social_meida" element={<SocialMedia />}></Route>
          <Route path="/qrcode/File_qr" element={<PDFQR />}></Route>
          <Route path="/qrcode/location_qr" element={<LocationQR />}></Route>
          <Route path="/qrcode/webform_qr" element={<WebformQR />}></Route>
          <Route path="/qrcode/campaign" element={<CampaignQR />}></Route>
          <Route path="/qreachknowmore" element={<QreachKnowMore />}></Route>
          <Route path="/qsealknowmore" element={<QsealKnowMore />}></Route>
          <Route path="/qrcode/shorturl" element={<ShortURL></ShortURL>}></Route>
          <Route path="/sitemap" element={<Sitemap></Sitemap>}></Route>
          <Route path="/schedule_demo" element={<ScheduleForm></ScheduleForm>}></Route>
          <Route path="/signup_create" element={<Onboarding></Onboarding>}></Route>
          <Route path="product/brandsom/privacypolicy" element={<BrandSom></BrandSom>}></Route> 
          <Route path="*" element={<NotFound></NotFound>}></Route>
        </Routes>
      <FooterNewInfra />
      <a href="https://wa.me/919741111594?text=Hello,%20I'm%20interested%20to%20know%20more%20about%20your%20products." className="whatsapp_float" target="_blank" rel="noopener noreferrer">
        <BsWhatsapp />
      </a>
      <CookieConsent  location="bottom" buttonText="Accept All" cookieName="myAwesomeCookieName2" expires={100} style={{ background: "#61C4FD", fontSize: "17px"}}
        buttonStyle={{ color: "#4e503b", fontSize: "17px", height: "45px"}}>We use cookies to give you best online experience using analytics, marketing tools and anonymous data. By continuing further you consent <br />to our privacy policy. For more information, read our <a href="/privacy">privacy policy.</a>
      </CookieConsent>
    </div>
    </div>
  );
}
export default App;
