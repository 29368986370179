import React from "react";
import img from "../../images/homeApp_engimg.png";
import "./customerEngagement.css";

export default function CustomerEngagement({
	heading,
	subHeading,
	url
}) {
	return (
		<div style={{background: "linear-gradient(180deg, rgba(128, 200, 243, 0.1)  0%, rgba(128, 200, 243, 0) 100%)"}}>
			<div className="container customer-engagement top-head">
				<div className="row">
					<div className="col text-center align-self-center p-4 p-sm-0">
						<img alt="suggested_blog" src={img} style={{width: "min(419.62px, 100%)", minWidth: "100px"}} />
					</div>
					<div className="col">
						<div>
							<h1>{heading || " Acquire data, build intelligence"}</h1>
							<h4>{subHeading || "Solutions that helps you acquire key consumer data, build intelligence, boost analytics, and adapt to evolving marketing processes, establish service networks and drive futureproof growth models."}</h4>
						</div>
						<a href={url || "/product"}>
                        {" "}
						<button className="btn btn-BlueBg">Learn More</button>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

