import React from "react";
import img from "../../images/coverphoto.png";
import "./topHead.css";

function TopHead() {
	return (
		<div className="pt-3" style={{background: "linear-gradient(0.63deg, rgba(49, 87, 239, 0.1) 1.58%, rgba(49, 87, 239, 0) 97.39%)"}}>
			<div className="container blogpost">
				<div className="px-magic text-center text-md-start">
					<h1>The product digitization is an opportunity</h1>
					<h4>28 February 2022 | By Venkat Terugu</h4>
				</div>
			</div>
			<img className="cover-img" src={img} alt="cover_blog" />
		</div>
	);
}

export default TopHead;
