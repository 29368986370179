import React from "react";
import img from "../../images/coverphoto.png";
import "../Blognumbereight/tophead.css";


function TopHead() {
	return (
		<div className="pt-3" style={{background: "linear-gradient(0.63deg, rgba(49, 87, 239, 0.1) 1.58%, rgba(49, 87, 239, 0) 97.39%)"}}>
			<div className="container blogpost">
				<div className="px-magic text-center text-md-start">
					<h1>Customer Trust Platform<hr></hr></h1> 
					<h2>Why its important</h2>
					<p>10 January 2025 | By Rohaan Joseph</p>
				</div>
			</div>
			<img className="cover-img" src={img} alt="cover_pic" />
		</div>
	);
}
export default TopHead;