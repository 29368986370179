import React from 'react'
import Card from 'react-bootstrap/Card';
import img1 from "../../images/inovation1.webp";
import img2 from "../../images/inovation2.webp";
import img3 from "../../images/inovation3.webp";
import img4 from "../../images/inovation4.webp";
import img5 from "../../images/inovation5.webp";
import img6 from "../../images/inovation6.webp";
import img7 from "../../images/inovation7.webp";
import img8 from "../../images/inovation8.webp";
import img9 from "../../images/inovation9.webp";
import img10 from "../../images/inovation10.webp";
import { Link } from "react-router-dom";
import './branddiff.css';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function branddiff() {
    return (
        <div className="brand-difference">

            <div className="container">
            <header>
                <h2>Experience the <span>Brandwise difference</span></h2>
                <p style={{ marginBottom: "25px" }}>We Empower Brands to:</p>
            </header>
                {/* Carousel for mobile only */}
                <div className="d-block d-md-none"> {/* This ensures only mobile devices see this */}
                    <Carousel interval={2000}>
                        <Carousel.Item>
                            <Card className="custom-card1" style={{border:"1px solid pink"}}>
                                <Card.Body>
                                    <Card.Title><strong>SCALE CUSTOMER ENGAGEMENT</strong></Card.Title>
                                    <Card.Text className="diff-text" style={{ fontSize: '14px', }}>
                                        Empower your brand to understand customers and elevate connections with various campaign options that boost engagements across any channel.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Carousel.Item>

                        <Carousel.Item>
                            <Card className="custom-card1" style={{border:"1px solid pink"}}>
                                <Card.Body>
                                    <Card.Title><strong>ACQUIRE NEW CUSTOMERS</strong></Card.Title>
                                    <Card.Text className="diff-text" style={{ fontSize: '14px',  }}>
                                        Drive Customer Acquisition by leveraging various innovative dynamic QR Campaigns, Personalized Engagement and Seamless Omnichannel Experiences.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Carousel.Item>

                        <Carousel.Item>
                            <Card className="custom-card1" style={{border:"1px solid pink"}}>
                                <Card.Body>
                                    <Card.Title><strong>RETAIN EXISTING CUSTOMERS</strong></Card.Title>
                                    <Card.Text className="diff-text" style={{ fontSize: '14px',}}>
                                        Utilize Actionable Insights to reduce churn and reactivate dormant customers by delivering unique experiences, offers, coupons, and more that keeps loyalty alive.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Carousel.Item>

                        <Carousel.Item>
                            <Card className="custom-card1" style={{border:"1px solid pink"}}>
                                <Card.Body>
                                    <Card.Title><strong>STREAMLINE PRODUCT DIGITIZATION</strong></Card.Title>
                                    <Card.Text className="diff-text" style={{ fontSize: '14px', }}>
                                        Transform your physical products into smart, connected assets enabling authentication, traceability, and interactive consumer experiences.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Carousel.Item>

                        <Carousel.Item>
                            <Card className="custom-card1" style={{border:"1px solid pink"}}>
                                <Card.Body>
                                    <Card.Title><strong>OPTIMIZE ROI WITH REALTIME ANALYTICS</strong></Card.Title>
                                    <Card.Text className="diff-text" style={{ fontSize: '14px',}}>
                                        Manage and track multiple campaign performances in one place with accurate real-time data to gain the competitive edge and maximize ROI.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Carousel.Item>
                    </Carousel>
                </div>

                {/* Grid layout for tablets and larger screens only */}
                <div className="d-none d-md-flex row"> {/* This ensures only larger devices see this */}
                    <div className="col-sm quard">
                        <Card className="custom-card1">
                            <Card.Body>
                                <Card.Title><strong>SCALE CUSTOMER ENGAGEMENT</strong></Card.Title>
                                <Card.Text className="diff-text" style={{ fontSize: '14px' }}>
                                    Empower your brand to understand customers and elevate connections with various campaign options that boost engagements across any channel.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-sm quard">
                        <Card className="custom-card1">
                            <Card.Body>
                                <Card.Title><strong>ACQUIRE NEW<br/> CUSTOMERS</strong></Card.Title>
                                <Card.Text className="diff-text" style={{ fontSize: '14px' }}>
                                    Drive Customer Acquisition by leveraging various innovative dynamic QR Campaigns, Personalized Engagement and Seamless Omnichannel Experiences.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-sm quard">
                        <Card className="custom-card1">
                            <Card.Body>
                                <Card.Title><strong>RETAIN EXISTING CUSTOMERS</strong></Card.Title>
                                <Card.Text className="diff-text" style={{ fontSize: '14px' }}>
                                    Utilize Actionable Insights to reduce churn and reactivate dormant customers by delivering unique experiences, offers, coupons, and more that keeps loyalty alive.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-sm quard">
                        <Card className="custom-card1">
                            <Card.Body>
                                <Card.Title><strong>STREAMLINE PRODUCT DIGITIZATION</strong></Card.Title>
                                <Card.Text className="diff-text" style={{ fontSize: '14px' }}>
                                    Transform your physical products into smart, connected assets enabling authentication, traceability, and interactive consumer experiences.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-sm quard">
                        <Card className="custom-card1">
                            <Card.Body>
                                <Card.Title><strong>OPTIMIZE ROI WITH REALTIME ANALYTICS</strong></Card.Title>
                                <Card.Text className="diff-text" style={{ fontSize: '14px' }}>
                                    Manage and track multiple campaign performances in one place with accurate real-time data to gain the competitive edge and maximize ROI.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div><br /><br />


                <div class="d-flex justify-content-center">
                    <Link to="/schedule_demo" rel="noreferrer">
                        <button class="btn btn-BlueBg" style={{ width: "200px" }}>Book a Call</button>
                    </Link>
                </div><br /><br />
                <h2>Enhance Efficiency Through <span>Innovation</span></h2>
                <div className='row'>
                    <div className='col-sm  quard'>
                        <div className="card text-center shadow">
                            <div className="card-top">
                                <img alt="brandwise" src={img5} />
                            </div>
                            <div className="card-body">
                                <p className="para">AI-Powered Authentication</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm quard' >
                        <div className="card text-center shadow">
                            <div className="card-top">
                                <img alt="brandwise" src={img6} />
                            </div>
                            <div class="card-body">
                                <p className="para">Omnichannel
                                    Engagement</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm quard'>
                        <div className="card text-center shadow">
                            <div className="card-top">
                                <img alt="brandwise" src={img1} />
                            </div>
                            <div className="card-body">
                                <p className="para">Integrated Loyalty
                                    Program</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm quard'>
                        <div className="card text-center shadow">
                            <div className="card-top">
                                <img alt="brandwise" src={img8} />
                            </div>
                            <div className="card-body">
                                <p className="para">Real-Time<br /> Analytics</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm quard'>
                        <div className="card text-center shadow">
                            <div className="card-top">
                                <img alt="brandwise" src={img7} />
                            </div>
                            <div className="card-body">
                                <p className="para">Versatile <br />Integration</p>
                            </div>
                        </div>
                    </div>
                </div><br /><br />
                <div className='row'>
                    <div className='col-sm quard'>
                        <div className="card text-center shadow">
                            <div className="card-top">
                                <img alt="brandwise" src={img9} />
                            </div>
                            <div className="card-body">
                                <p className="para">Smart Packaging
                                    Technology</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm quard'>
                        <div className="card text-center shadow">
                            <div className="card-top">
                                <img alt="brandwise" src={img3} />
                            </div>
                            <div class="card-body">
                                <p className="para">First Party Data
                                    Collection &
                                    Utilization</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm quard'>
                        <div className="card text-center shadow">
                            <div className="card-top">
                                <img alt="brandwise" src={img2} />
                            </div>
                            <div className="card-body">
                                <p className="para">Build Your Own
                                    (BYC) Campaign</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm quard'>
                        <div className="card text-center shadow">
                            <div className="card-top">
                                <img alt="brandwise" src={img4} />
                            </div>
                            <div className="card-body">
                                <p className="para">Customer Journey
                                    Mapping</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm quard'>
                        <div className="card text-center shadow">
                            <div className="card-top">
                                <img alt="brandwise" src={img10} />
                            </div>
                            <div className="card-body">
                                <p className="para">ROI
                                    Optimization<br/> Suite</p>
                            </div>
                        </div>
                    </div>
                </div>


            </div><br /><br /><br />
        </div>
    )
}
