import React from "react";
import './tophead.css'
import img1 from "../../images/footwear_banner.webp";
import img2 from "../../images/retail1.webp";

export default function TopHead() {
    return (
        <>
            <div>
                <img className="cover-image" src={img1} alt="cover_pic" />
            </div>
            <div className="container retail-tophead">
                <div className="retail-content">

                    <div className="row">
                        <div className="col-md-6 order-2 order-md-2 ">
                            <h1>Transform Fleeting Attentions into Enduring Value</h1>
                            <p>Deliver Unique experiences across customer
                                Touchpoints and drive Omni-Channel Sales.
                                Understand your customers and ride on *3x
                                higher conversions. Harness the power of
                                Brandwise and become a brand of the future, Today!
                            </p>
                            <br></br>
                            <div className="row">
                                <div className="col">
                                    <input type="text" placeholder="Full Name" />
                                </div>
                                <div className="col">
                                    <input type="text" placeholder="Business Email" />
                                </div>
                            </div>
                            <button type="button">Connect with an expert</button>
                        </div>
                        <div className="col-md-6 order-1 order-md-2 " style={{ textAlign: "right" }}>
                            <img src={img2} alt="Man receiving omnichannel alerts and suggestions from whatsapp, email, messages etc to 
                            purchase clothes he is interested in" style={{ width: "100%", height: "100%" }} title=" Man receiving omnichannel alerts for clothing purchases." />
                        </div>
                    </div>
                </div>
                <br></br>
                <div className="highlight-content" id="challengesButton">
                    <div className="tab-container">
                        <a href="#challengesContent" className="tab"><button>Challenges</button></a>
                        <span className="separator"></span>
                        <a href="#ROIcontent" className="tab"><button>Features</button></a>
                        <span className="separator"></span>
                        <a href="#brandContent" className="tab"><button>Use Cases</button></a>
                        <span className="separator"></span>
                        <a href="#feedbackContent" className="tab"><button>Testimonies</button></a>
                    </div>
                </div><br /><br />
            </div>
        </>
    );
}