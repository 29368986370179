import React from "react";
import img from "../../images/Article img.png";
import "./suggestedBlog.css";

function SuggestedBlog() {
	return (
	<div style={{background: "linear-gradient(0.63deg, rgba(49, 87, 239, 0.1) 1.58%, rgba(49, 87, 239, 0) 97.39%)"}}>
		<div className="container suggestblog">
			<div className="row">
				<div className="col-12 col-md-5">
					<h1>Suggested Article</h1>
					<h2>How encryption changed the world</h2>
					<h4>Encryption makes the modern world go round. Every time you make a mobile phone call, buy something with a credit card in a shop or on the web, or even get cash from an ATM, encryption bestows upon that transaction...</h4>
					<a href='/encryption'><button className="btn btn-BlueBg">Read Article</button></a>
				</div>
				<div className="col-12 col-md-2"></div>
				<div className="col-12 col-md-5">
					<img alt="suggested_article" src={img}  className="suggested_blog_image"/>
				</div>
			</div>
		</div>
	</div>
	);
}

export default SuggestedBlog;
