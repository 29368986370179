import React, {useEffect}  from "react";
import TopHead from "../components/PersonalCare/tophead";
import BeyondQR from "../components/PersonalCare/beyondqr";
import Solution from "../components/PersonalCare/solution";
import BoostDemo from "../components/PersonalCare/boostdemo";
import { Helmet } from 'react-helmet';
import whitelogo from "../images/favicon.png";

export default function PersonalCarePage() {
    useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
    return (
      <div>
        <Helmet>
          <title>Personal Care Premium Engagement and Advanced Digitization</title>
          <meta name="description" content="Deliver Luxurious Customer Experiences for your Personal Care brand using Brandwise’s innovative technology for Engagement, Digitization and Authentication." />
          {/* Open Graph tags for social media sharing */}
          <meta property="og:title" content="Personal Care Premium Engagement and Advanced Digitization" />
          <meta property="og:description" content="Deliver Luxurious Customer Experiences for your Personal Care brand using Brandwise’s innovative technology for Engagement, Digitization and Authentication." />
          <meta property="og:url" content="https://ciphercode.co/personalcare" />
          <meta property="og:image" content={whitelogo} />
          <meta property="og:type" content="website" />
          {/* Twitter Card for better Twitter sharing */}
          <meta name="twitter:title" content="Personal Care Premium Engagement and Advanced Digitization" />
          <meta name="twitter:description" content="Deliver Luxurious Customer Experiences for your Personal Care brand using Brandwise’s innovative technology for Engagement, Digitization and Authentication." />
        </Helmet>
        <TopHead />
        <BeyondQR />
        <Solution />
        <BoostDemo />
      </div>
    )
}
