import React, {useEffect}  from "react";
import { Helmet } from "react-helmet";
import whitelogo from "../images/favicon.png";

export default function Qreport() {
    useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
    const headStyles = {
      marginTop:'80px',
      marginBottom:'80px',
      fontWeight: '900',  
    }
    return (
      <div style={{background: "linear-gradient(0.63deg, rgba(49, 87, 239, 0.1) 1.58%, rgba(49, 87, 239, 0) 97.39%)", textAlign: '-webkit-center'}}>      
      <Helmet>
			<title>Enterprise Platform for engagement and digitization needs</title>
			<meta name="description" content="Discover Brandwise’s solutions for product digitization, customer engagement, and authentication. Enhance ROI, engagement and security." />
			<meta name="keywords" content="Brandwise, Digitization, Engagement, Authentication, Security" />
			{/* Open Graph tags for social media sharing */}
			<meta property="og:title" content="Enterprise Platform for engagement and digitization needs" />
			<meta property="og:description" content="Discover Brandwise’s solutions for product digitization, customer engagement, and authentication. Enhance ROI, engagement and security." />
			<meta property="og:url" content="https://ciphercode.co/product/qReport/" />
			<meta property="og:image" content={whitelogo} />
			<meta property="og:type" content="website" />
			{/* Twitter Card for better Twitter sharing */}
			<meta name="twitter:title" content="Enterprise Platform for engagement and digitization needs" />
			<meta name="twitter:description" content="Discover Brandwise’s solutions for product digitization, customer engagement, and authentication. Enhance ROI, engagement and security." />
		  </Helmet>  
              <h1 style={headStyles}>Coming Soon</h1>
              <br />
              <br />
              <br />
              <br />
        </div>
    )
}