import React from "react";
import TopHead from "../components/UseCase/tophead";
import Usecaselist from "../components/UseCase/usecaselist";

export default function Retail() {
  
    return (
        <div>
            <TopHead></TopHead>
            <Usecaselist></Usecaselist>
        </div>
    )
}

