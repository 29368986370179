import React, { useRef } from "react";
import img1 from "../../images/features_img1.webp";
import img2 from "../../images/features_img2.webp";
import img3 from "../../images/features_img3.webp";
import img4 from "../../images/features_img4.webp";
import img5 from "../../images/features_img5.webp";
import "./features.css";

const featuresData = [
  {
    img: img1,
    heading: "Offline Campaigns",
    description:
      "Enhance in-store experiences with QR code campaigns offering instant promotions, gamified interactions, and loyalty rewards. Customers access deals, provide feedback, or join contests, boosting engagement and repeat visits. Real-time insights and dynamic updates ensure campaigns stay relevant and impactful.",
    list: [
      "Increase in store walk-ins by *8x",
      "*3x Increase in Repeat Purchases",
      "*40% Decrease in Customer Acquisition Cost",
      "Leverage Dynamic Coupons - *3x higher conversions",
    ],
  },
  {
    img: img2,
    heading: "Meta Campaigns",
    description:
      "QReach amplifies the effectiveness of Meta campaigns by combining data-driven insights, AI, personalized targeting, and seamless integration across channels, delivering superior results for businesses.",
    list: [
      "Up to *200x ROAS, then go beyond",
      "*2x Ad Reach via Brandwise",
      "Geo Location Targeted Ads",
      "Accurate ROI Metrics & Data",
    ],
  },
  {
    img: img3,
    heading: "Multi-Channel Integration",
    description:
      "Seamless campaign management across SMS, WhatsApp, Email, and Meta, all from a unified platform, enabling brands to launch consistent and impactful campaigns anywhere.",
    list: [
      "*250% Higher Engagement Rates",
      "*90% Higher Retention",
      "Unified Platform for all Campaign Metrics Across Channels",
      "*40% Increase in Store Conversions",
    ],
  },
  {
    img: img4,
    heading: "Real ROI Calculation",
    description:
      "SQReach tracks real ROI by linking conversions and sales to specific campaigns. With real-time insights and robust analytics, businesses can measure and optimize campaign effectiveness, ensuring maximum impact and better budget and resource management.",
    list: [
      "Accurate Sales Data Linked to Campaigns",
      "Simplified and Easy to Understand Metrics",
      "Modern Data Representation",
      "Effortless Data Export",
    ],
  },
  {
    img: img5,
    heading: "Customizable Loyalty Programs",
    description:
      "QReach empowers businesses to create impactful loyalty programs with personalized rewards, exclusive deals, and gamified experiences. With real-time insights, brands can adapt to customer preferences, boosting engagement and maximizing retention.",
    list: [
      "Move from KYC → UYC (Understand your Customer)",
      "Accurately anticipate customer needs & trends with predictive AI",
    ],
  },
];

export default function Features() {
  const cardsRef = useRef([]);
  return (
    <div className="features-content container">
      <h2 className="features-title">Features</h2>
      <ul id="cards">
        {featuresData.map((feature, index) => (
          <li className="card" key={index} ref={(el) => (cardsRef.current[index] = el)}>
            <div className="card-body">
              <div className="row feature-row">
                <div className="col s12 m6 feature-image-card">
                  <img src={feature.img} alt="Feature" className="feature-image" />
                </div>
                <div className="col s12 m6 feature-text-container">
                  <h3 className="feature-heading">{feature.heading}</h3>
                  <p className="feature-description">{feature.description}</p>
                  <ul className="feature-list">
                    {feature.list.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
