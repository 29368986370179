import React, {useEffect}  from "react";
import TopHead from "../components/Careers/tophead";
import EmployeeSay from "../components/Careers/employeesay";
import TalkBusiness from "../components/Careers/talkbusiness";
import { Helmet } from "react-helmet";
import whitelogo from "../images/favicon.png";

export default function Careerpage() {
    useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
    return (
      <div>
        <Helmet>
          <title>Join Ciphercode to Build the future and your career</title>
          <meta name="description" content="Join our dynamic team at Ciphercode! Explore exciting career opportunities where innovation, collaboration, and personal growth thrive. Shape the future with us." />
          <meta name="keywords" content="Join our team, Careers, personal growth, local employment opportunities" />
          {/* Open Graph tags for social media sharing */}
          <meta property="og:title" content="Join Ciphercode to Build the future and your career" />
          <meta property="og:description" content="Join our dynamic team at Ciphercode! Explore exciting career opportunities where innovation, collaboration, and personal growth thrive. Shape the future with us." />
          <meta property="og:url" content="https://ciphercode.co/career" />
          <meta property="og:image" content={whitelogo} />
          <meta property="og:type" content="website" />
          {/* Twitter Card for better Twitter sharing */}
          <meta name="twitter:title" content="Join Ciphercode to Build the future and your career" />
          <meta name="twitter:description" content="Join our dynamic team at Ciphercode! Explore exciting career opportunities where innovation, collaboration, and personal growth thrive. Shape the future with us." />
        </Helmet>
        <TopHead />
        <EmployeeSay />
        <TalkBusiness />
      </div>
    )
}