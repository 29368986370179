import React from "react";
import './tophead.css'
import img from "../../images/industry1.webp";

export default function TopHead() {
    return (
        <>
            <div className="container packing-tophead">
                <div className="packing-content">
                    <div className="row">
                        <div className="col-md-6">
                            <h1>Empower the “Smart” in Smart Packaging</h1>
                            <p>Being smart in packaging industry demands a
                                blend of <span>intelligence, connectivity,</span> and
                                <span> sustainability</span>. Achieving this trifecta in the era of
                                experience economy is a formidable challenge.
                                Brandwise® by Ciphercode is transforming the way
                                smart packaging empowers their customers.
                            </p>
                            <br></br>
                            <div className="row">
                                <div className="col">
                                    <input type="text" placeholder="Full Name" />
                                </div>
                                <div className="col">
                                    <input type="text" placeholder="Mobile Number" />
                                </div>
                            </div>
                            <button type="button">Connect with an expert</button>
                        </div>
                        <div className="col-md-6" style={{ textAlign: "right" }}>
                            <img src={img} alt="Diagram of the components that make up smart packaging such as 
                    intelligence, connected and sustainable packaging" style={{ width: "85%" }} title="Smart packaging components include intelligence, connectivity,and sustainability." />
                        </div>
                    </div>
                </div>
                <br></br>
                <div className="highlight-content">
                    <div className="tab-container">
                        <a href="#highlightsContent" className="tab"><button>Highlights</button></a>
                        <span className="separator"></span>
                        <a href="#useCasesContent" className="tab"><button>Use Cases</button></a>
                        <span className="separator"></span>
                        <a href="#feedbackContent" className="tab"><button>Testimonies</button></a>
                    </div>
                </div>

            </div>

        </>
    );
}