import React, {useEffect} from "react";
import TopHead from "../components/AboutUs/tophead";
import Aboutme  from "../components/AboutUs/aboutme";
import Footertwo from '../components/Home/footertwo';
import whitelogo from "../images/favicon.png";
import { Helmet } from "react-helmet";

function About() {
  useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
	return (
    <div>
      <Helmet>
          <title>Becoming the most preferred customer intelligence platform</title>
          <meta name="description" content="Learn More About us and Discover what makes Ciphercode, Ciphercode. Our Journey to become the most preferred customer intelligence platform is an exciting one!" />
          {/* Open Graph tags for social media sharing */}
          <meta property="og:title" content="Becoming the most preferred customer intelligence platform" />
          <meta property="og:description" content="Learn More About us and Discover what makes Ciphercode, Ciphercode. Our Journey to become the most preferred customer intelligence platform is an exciting one!" />
          <meta property="og:url" content="https://ciphercode.co/aboutus" />
          <meta property="og:image" content={whitelogo} />
          <meta property="og:type" content="website" />
          {/* Twitter Card for better Twitter sharing */}
          <meta name="twitter:title" content="Becoming the most preferred customer intelligence platform" />
          <meta name="twitter:description" content="Learn More About us and Discover what makes Ciphercode, Ciphercode. Our Journey to become the most preferred customer intelligence platform is an exciting one!" />
      </Helmet>
      <TopHead></TopHead>
      <Aboutme></Aboutme>
      <Footertwo></Footertwo>
    </div>
    )
}
export default About;