import React from "react";
import './visual.css';
import img1 from "../../images/visualize_img.webp";


export default function Solution() {
    return (
        <div className="container qreachknowmore-visual">
            <div className="visual-content">
                <h2>Visualize <span>QReach</span></h2>
                <img src={img1} alt=" " style={{width:"100%",height:"100%"}}/>
            </div>
            <div id="discoverContent"></div><br /><br /> <br />
            <div id="feedbackContent"></div><br /><br /> <br />
        </div>
        

    );
}