import React from "react";
import './tophead.css'


export default function TopHead() {
    return (
        <div style={{backgroundColor:'#AED3FD', textAlign:'center'}}>
            <div className="container usetophead">
                 <h1>Use Cases</h1>
                  <p className="px-6 py-3">Discover the Impact of Brandwise on <br></br>Various Industries Across the World</p>
            </div>
        </div>
    )
}