import React from 'react';
import Card from 'react-bootstrap/Card';
import img1 from "../../images/qseal_more1.webp";
import img2 from "../../images/qseal_more5.webp";
import img3 from "../../images/qseal_more2.webp";
import './industry.css';

export default function Industry() {
    return (
        <div className="industry-content container">
            <h2 className="industry-title ">Industry Challenges</h2>
            <div className="industry-cards row g-4">
                {/* Card 1 */}
                <div className="col-md-4">
                    <Card className="industry-card">
                        <Card.Img variant="top" src={img1} alt="Fragmented Engagement" className='indust-img'/>
                        <Card.Body>
                            <Card.Title className="industry-card-title">Counterfeit Products</Card.Title><br />
                            <Card.Text className="industry-card-text">
                                Businesses often struggle to create
                                consistent customer experiences
                                across multiple touchpoints like
                                social media, in-store, and digital
                                platforms.
                            </Card.Text><br /><br />
                        </Card.Body>
                    </Card>
                </div>
                {/* Card 2 */}
                <div className="col-md-4">
                    <Card className="industry-card">
                        <Card.Img variant="top" src={img2} alt="Personalized Marketing" className='indust-img'/>
                        <Card.Body>
                            <Card.Title className="industry-card-title">Consumer Trust Deficit</Card.Title><br />
                            <Card.Text className="industry-card-text">
                                Generic campaigns fail to resonate
                                with diverse customer groups,
                                resulting in low engagement and
                                conversion rates.
                            </Card.Text><br /><br />
                        </Card.Body>
                    </Card>
                </div>
                {/* Card 3 */}
                <div className="col-md-4">
                    <Card className="industry-card">
                        <Card.Img variant="top" src={img3} alt="Data Utilization" className='indust-img'/>
                        <Card.Body>
                            <Card.Title className="industry-card-title">Disconnected Customer Experience</Card.Title>
                            <Card.Text className="industry-card-text">
                                Many businesses fail to effectively
                                use consumer data for actionable
                                insights, limiting their ability to
                                adapt campaigns dynamically.
                            </Card.Text><br /><br />
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}

