import React from 'react';
import img1 from "../../images/Product_Digitization2.png";
import img3 from "../../images/img_real_time.png";
import img4 from "../../images/product_authentication.png";
import img5 from "../../images/Personalization.png";
import './seamless.css';

export default function Seamless() {
	return (
		<div className="container seamless-content" id="highlightsContent">
        <div className="row">
            <div className="col-md-5 order-md-2">
                <div className="packaging-content2">
                  <h2>Seamless Product Digitization</h2>
                  <p>Revolutionize the packaging process, making it easy
                   for brands to integrate technology and create an
                   inspiring experience for their customers.</p>
                   <ul>
                      <li>Enable brands to digitize their products efficiently across SKUs, volumes and scale.</li>
                      <li>Seamlessly integrate stakeholders to map product and consumer journey.</li>
                      <li>Bridge gap between physical products and digital experiences for new value.</li>
                      <li>Develop consumer advocacy, first party data and brand intelligence within the privacy framework.</li>
                   </ul>
                </div>
            </div>
            <div className="col-md-1 order-md-2"></div>
            <div className="col-md-6 order-md-2 " style={{textAlign:"center"}}>
                <img src={img1} className='image-set' alt="Two woman smiling at the interactive packaging of 
                personal care products enabled by Brandwise " style={{width:'100%', height:'100%'}} title="Interactive personal care packaging delights customers." />
            </div>
            </div><br></br><br></br>
            <div className="row">
                <div className="col-md-6 order-md-2" style={{textAlign:"center"}}>
                <img src={img3} alt="Admin using Brandwise to track and trace inventory during transit, 
                increasing supply chain transparency."  style={{width:'100%',height:'100%'}} title="Admin tracking inventory in transit for supply chain transparency."/>
                </div>
                <div className="col-md-1 order-md-2"></div>
                <div className="col-md-5 order-md-2">
                    <div className="packaging-content2" >
                        <h2>Real-time Inventory Visibility Solutions</h2>
                        <p>Strengthen your partnership by offering:</p>
                        <ul>
                            <li>Deliver 100% GS1 Compliant Track & Trace solutions.</li>
                            <li>25% reduction in stockouts through real-time insights by integrating the store intelligence.</li>
                            <li>Boost operational efficiency with transparent supply and reverse supply chain management.</li>
                            <li>Instantly reflect field stock status on brand dashboards.</li>
                        </ul>
                    </div>
                </div>
            </div><br></br><br></br>
            <div className="row">
            <div className="col-md-5 order-md-2">
                <div className="packaging-content2">
                  <h2>Product Authentication Collaboration</h2>
                  <p>Enhance brand trust through collaborative security and reinforce your partnership.</p>
                   <ul>
                      <li>Implement Product Digitization and app-less Authentication.</li>
                      <li>Build brand trust by reducing counterfeit incidents and gaining real-time ground insights.</li>
                      <li>Act effectively against incidents with or without expensive ground forces.</li>
                      <li>Expand consumer participation and foster brand community involvement.</li>
                   </ul>
                </div>
            </div>
            <div className="col-md-1 order-md-2"></div>
            <div className="col-md-6 order-md-2" style={{textAlign:"center"}}>
                <img src={img4} alt="Increase trust in collaboration with advanced product 
                authentication enabled by a simple QR code scan" style={{width:'100%',height:'100%'}} title="Boost collaboration trust with simple QR code authentication." />
            </div>
            </div><br></br><br></br>
            <div className="row">
                <div className="col-md-6 order-md-2" style={{textAlign:"center"}}>
                <img src={img5} alt="Deliver hyper-personalized packaging experiences for your customers with Brandwise 
                AI that collects accurate first party data" style={{width:'100%',height:'100%'}} title="Hyper-personalized packaging powered by Brandwise AI."/>
                </div>
                    <div className="col-md-1 order-md-2"></div>
                <div className="col-md-5 order-md-2">
                    <div className="packaging-content2">
                        <h2>Hyper-personalize Packaging Experience</h2>
                        <p>Co-create with brands to promote packaging innovations</p>
                        <ul>
                            <li>With lasting experiences and convenience of technology Increase brand loyalty by 30%.</li>
                            <li>Showcase expertise in crafting unique and memorable personalization.</li>
                            <li>Personalize the entire value chain using first-party data and learned intelligence.</li>
                        </ul>
                    </div>
                </div>
            </div>
            
        </div>
    )
}