import React from "react";
import './sitemap.css';
import {Link} from 'react-router-dom';
import whitelogo from "../images/favicon.png";
import { Helmet } from "react-helmet";


export default function Sitemap() {
   
    return (
        <>
        <Helmet>
        <title>Customer Engagement and Digitization Leading Solutions</title>
        <meta name="description" content="Cutting-edge solutions for customer engagement, product digitization and authentication. Boost brand loyalty, trust and sales with custom QR campaigns." />
        <meta name="keywords" content="Ciphercode, Brandwise, Customer Engagement, Product Digitzation, Authentication, Brand Loyalty" />
        <meta property="og:title" content="Customer Engagement and Digitization Leading Solutions" />
        <meta property="og:description" content="Cutting-edge solutions for customer engagement, product digitization and authentication. Boost brand loyalty, trust and sales with custom QR campaigns." />
        <meta property="og:url" content="https://ciphercode.co/sitemap/" />
        <meta property="og:image" content={whitelogo} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Customer Engagement and Digitization Leading Solutions" />
        <meta name="twitter:description" content="Cutting-edge solutions for customer engagement, product digitization and authentication. Boost brand loyalty, trust and sales with custom QR campaigns." />
      </Helmet>
      <div style={{background: "linear-gradient(0.63deg, rgba(49, 87, 239, 0.1) 1.58%, rgba(49, 87, 239, 0) 97.39%)", paddingBottom:"20px"}}>
        <div className="container sitehead">
        <h1>Sitemap</h1><hr />
        <h3>About Ciphercode<sup>®</sup></h3>
        <ul>
            <li><Link to ="/">Brandwise<sup>®</sup></Link></li>
            <li><Link to = "/contactus">Contact us</Link></li>
            <li><Link to = "/aboutus">About us</Link></li>
            <li><Link to = "/product">Product</Link></li>
            <li><Link to = "/signup">Sign-up</Link></li>
            <li><Link to = "/privacy">Privacy policy</Link></li>
            <li><Link to = "/schedule_demo">Get Demo</Link></li>
        </ul>< hr />
        <h3>Product</h3>
        <ul>
            <li><Link to ="/product/qSeal">Qseal<sup>®</sup></Link></li>
            <li><Link to ="/product/qReach">QReach<sup>®</sup></Link></li>
            <li><Link to ="/product/qReport">QReport<sup>®</sup></Link></li>
            {/*<li><Link to ="/pricing">Pricing</Link></li>*/}
            </ul><hr />
        <h3>Information Resources</h3>
        <ul>
            <li><Link to ="/homeappliances">Home Appliances</Link></li>
            <li><Link to ="/blog">Blog</Link></li>
            <li><Link to ="/encryption">Encryption</Link></li>
            <li><Link to ="/personalcare">Personal Care</Link></li>
            <li><Link to ="/automobile">Automobiles</Link></li>
            <li><Link to ="/resources">Cryptoblocks</Link></li>
            <li><Link to ="/packaging">Packaging</Link></li>
            <li><Link to ="/footwear">Footwear</Link></li>
            <li><Link to ="/retail">Retail</Link></li>
            <li><Link to ="/career">Careers</Link></li>
            <li><Link to ="/partnershipprogram">Partnership Program</Link></li>
        </ul><hr />
        <h3>Cryptoblocks QR Generate</h3>
        <ul>
            <li><Link to ="/qrcode/url">Website Link/Url</Link></li>
            <li><Link to ="/qrcode/whatsapp">WhatsApp</Link></li>
            <li><Link to ="/qrcode/social_meida">Social Media</Link></li>
            <li><Link to ="/qrcode/File_qr">PDF/Media File</Link></li>
            <li><Link to ="/qrcode/location_qr">Location</Link></li>
            <li><Link to ="/qrcode/campaign">Campaign</Link></li>
        </ul>
        </div>
      </div>
        </>
    )
}