import React from "react";
import img from "../../images/Blogpost Counterfeit.svg";
import img2 from "../../images/Cteam1.png";
import "./blogcontent.css";
import "./comments.css";

function BlogContent() {
	return (
		<>
			<div className="position-relative">
				<div className="container blog-content">
					<p><span className="big-letter">P</span>eople buy luxury goods for variety of emotional satisfactions; such strong feelings are closely associated with the authenticity - originality- of the product.
					</p>
					<p>Researchers at Yale have determined that this quest for originality (Authentic) develops early in childhood. A study that tried to convince children that a cloning machine had produced their favourite toy, found that most children refused to accept the duplicate though they are identical. </p>
					<div className="p-1 p-md-4 img-wrap position-relative">
						<div className="blue-div"></div>
						<img className="main-img" src={img} alt="Digital security concept with a laptop displaying a lock, keys, 
						shopping bags and files, symbolizing secure commerce" title="Digital security concept identifying original from clone." />
					</div>
				    <p>But the story in physical world is little different, production technology got advanced, anything physical can be replicated and reproduced, it needs forensic lab to distinguish between original and deceived products, yet the success has its own limits. What comes to rescue in this situation? nothing new, again the production technology, which leverages the non-Fungible identities at scale.</p>
					<p>Wondering what that means? don’t worry its much simpler than it sounds. The cryptography has transformed the world, and made the space secure and safe, if technology leveraged efficiently. Upon that, connectivity opened the information free flow and public participation. Combined solution opens the doors for completely new world of authenticity, where the possibilities are unlimited and relevant to the new world of digital experiences such as metaverse and AI influence.</p>
					<p>Gone the days, now experiences taken over the price and product, consumers are willing to pay cost for the experiences they demand, it is an opportunity and challenge. Cryptoblocks platforms such as Brandwise® by Ciphercode turns the challenges to opportunities to scalable brand’s revenue.</p>
					<p>Cryptoblocks works on the principle of Cryptography, in the advanced technology epoch, cryptography makes it hard and impossible for unauthorized to own and represent objects authentically. Public gets power to authenticate and access to dynamic information directly from the producers of the object. </p>
					<hr />
					<div className="d-flex flex-row blog-author">
						<img src={img2} alt="profile_pic" className="rouned-circle"/>
						<div className="px-3 d-flex flex-row flex-wrap justify-content-between w-100">
							<div className="align-self-center" style={{minWidth: "224px"}}>
								<p className="fw-bold">Venkat Terugu</p>
								<p>Co-Founder at Ciphercode </p>
							</div>
							<div className="align-self-center" style={{paddingRight: "5vw", minWidth: "calc(110px + 5vw)"}}>
								<p className="fw-bold">Date</p>
								<p>5 May 2022</p>
							</div>
						</div>
					</div>
					<div className="mt-4">
			         <div className="col-md-12" id="fbcomment">
			         <div className="fb-comments" data-href="https://ciphercode.co/blogpost/3" data-width="700" data-numposts=""></div>
			        </div>
		</div>
				</div>
				<div className="blue-line hide-sm">
					<svg width="188" height="11" viewBox="0 0 188 11" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect x="-1" y="0.0644531" width="188.487" height="10.752" fill="#3157EF" />
					</svg>
				</div>
			</div>
		</>
	);
}

export default BlogContent;
