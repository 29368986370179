import React, {useEffect} from "react";
import TopHead from "../components/Blognumberfive/topHead";
import BlogContent from "../components/Blognumberfive/blogcontent";
import { Helmet } from "react-helmet";
import whitelogo from "../images/favicon.png";

function Blog() {
	useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
	return (
		<div>
			<Helmet>
                <title>First Party Data and Navigating the Cookieless Future</title>
                <meta name="description" content="Leverage first-party data for building trust, enhancing customer relationships, and optimizing marketing strategies during the demise of third party cookies" />
                <meta name="keywords" content="First Party Data, Third Party Cookies, Customer data platform" />
                {/* Open Graph tags for social media sharing */}
                <meta property="og:title" content="First Party Data and Navigating the Cookieless Future" />
                <meta property="og:description" content="Leverage first-party data for building trust, enhancing customer relationships, and optimizing marketing strategies during the demise of third party cookies" />
                <meta property="og:url" content="https://ciphercode.co/first_party" />
                <meta property="og:image" content={whitelogo} />
                <meta property="og:type" content="website" />
                {/* Twitter Card for better Twitter sharing */}
                <meta name="twitter:title" content="First Party Data and Navigating the Cookieless Future" />
                <meta name="twitter:description" content="Leverage first-party data for building trust, enhancing customer relationships, and optimizing marketing strategies during the demise of third party cookies" />
            </Helmet>
			<TopHead />
			<BlogContent />
		</div>
	);
}
export default Blog;
