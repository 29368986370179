import React, { useEffect, useRef } from "react";
import img1 from "../../images/qseal_more4.webp";
import img2 from "../../images/qseal_img9.webp";
import img3 from "../../images/Security Features (1).webp";
import img4 from "../../images/qseal_more6.webp";
import img5 from "../../images/qseal_more7.webp";
import "./features.css";

const featuresData = [
  {
    img: img1,
    heading: "Product Digitization & Authentication",
    description1:
      "Counterfeit is an old problem, society started living with-it by doing minimal about it, because the nature of problem and lack of right solutions, that’s not true anymore with Brandwise-QSeal",
    description2:
    "QSeal Cryptoblock is not only a patented technology but evolving every day to meet the industry transformations and consumer aspirations. It safeguards brands by generating unique, tamper-proof encrypted digital identities for products. AI-powered anti-duplication agents prevent counterfeits, ensuring consumer safety and brand reputation, hence the lasting trust.",
    list: [
      
    ],
  },
  {
    img: img2,
    heading: "Remote Consumer Insights (through 1st Party Data)",
    description1:
      "QSeal turns product authentication into customer engagement by linking verification to tailored content, loyalty rewards, or personalized offers. This approach enhances authenticity, builds loyalty, and drives repeat purchases through deeper customer connections like never before.",
    list: [
      "Turn every physical product into smart touch-point",
      "Build data, earn insights & deliver experiences",
      "Digitize content, save materials",
      
    ],
  },
  {
    img: img3,
    heading: "Security Features",
    description1:
      "QSeal has proven itself as a world best security platform for physical products, due to its security strength and comprehensiveness covering for wide market needs.",
    list: [
      "Encrypted QR – Unique digital certificate for manufactured article at production scale",
      "Secure Code – “Scratchable unique code” + “Brand landing page QR” to facilitate consumer authentication.",
      "Dual QR code – Two cryptographically linked Overt and covert QR codes for authentication",
      "And few more proven codes",
    ],
  },
  {
    img: img4,
    heading: "AI Agents Monitoring",
    description1:
      "QSeal helps brands track and manage product journeys from manufacturing to consumer verification. It provides real-time insights into product location, distribution, and customer interactions. Brands can monitor engagement and behaviors, optimizing operations, enhancing customer experiences, and ensuring product authenticity.",
    description2: 
    "Digital certificates are self sufficient to authenticate Brand signature, AI agents monitor copied QR codes or ground incidents at scale of internet, to alert brand in the Realtime. Now brands don’t need waste time waiting until physical complaints, AI agents does the job." ,
    list: [
      
    ],
  },
  {
    img: img5,
    heading: "Effortless Authentication",
    description1:
      "The First foundation principle of Brandwise is the effortless consumer engagement, as simple as a click on smartphone.",
    description2:
      "QSeal understands the diversity of the consumers and products, the solution built on catering these wide range of use-cases in interest of comprehensively eliminating the gap between brand and consumer.",
    list: [
      "Hyper-Personalized Landing Pages",
      "App-Less Product Authentication and Customer Engagement Experience.",
      "Brand API integration for additional services",
    ],
  },
];

export default function Features() {
  const cardsRef = useRef([]);
  return (
    <div className="features-content container">
      <h2 className="features-title">Features</h2>
      <ul id="cards">
        {featuresData.map((feature, index) => (
          <li className="card" key={index} ref={(el) => (cardsRef.current[index] = el)}>
            <div className="card-body">
              <div className="row feature-row">
                <div className="col s12 m6 feature-image-card">
                  <img src={feature.img} alt="Feature" className="feature-image" />
                </div>
                <div className="col s12 m6 feature-text-container">
                  <h3 className="feature-heading">{feature.heading}</h3>
                  <p className="feature-description">{feature.description1}</p>
                  <p className="feature-description">{feature.description2}</p>
                  <ul className="feature-list">
                    {feature.list.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
