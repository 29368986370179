import React, {useEffect}  from "react";
import TopHead from "../components/QReachKnowMore/tophead";
import Industry from "../components/QReachKnowMore/industry";
import Solution from "../components/QReachKnowMore/solution";
import Features from "../components/QReachKnowMore/features";
import Visual from "../components/QReachKnowMore/visual";
import Discover from "../components/Home/discover";
import Feedback from "../components/Home/feedback";
import FAQ from "../components/QReachKnowMore/faq";



export default function Qreachknowmore() {
    useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
    return (
      <div>
      <TopHead />
      <Industry />
      <Solution />
      <Features />
      <Visual />
      <Discover />
      <Feedback />
      <FAQ />
    </div>
    )
}