import React from 'react';
import img1 from "../../images/reviewers1.webp";
import img2 from "../../images/reviewers2.webp";
import img3 from "../../images/reviewers3.webp";
import img4 from "../../images/reviewers4.webp";
import img5 from "../../images/reviewers5.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './feedback.css';
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";
import { NextArrow, PrevArrow } from "./CustomArrows";
import Card from 'react-bootstrap/Card';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/poppins/400.css"; // Specify weight
import "@fontsource/poppins/400-italic.css"; // Specify weight and style

export default function Feedback() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="container feedback-partner">
            <h2>Brands Embrace <span>Brandwise</span></h2>
            <div className="d-block d-md-none row">
            <Carousel interval={2000} controls={false} indicators={false} pause={false}> {/* Automatic sliding */}
            <Carousel.Item>
                <div className="col-md-3 card-column" >
                    <Card className="custom-card" id="dis-card" >
                        <Card.Img variant="top" src={img1} alt="reviewer1" />
                        <Card.Body>

                            <Card.Text>
                                <p>
                                    Brandwise Revolutionized our <span>Consumer Engagement </span>
                                    Strategy, enabling us to increase our store footfall and
                                    sales. We are very impressed with the <span>dynamic landing
                                        pages</span>, diverse <span>campaign options</span> and ease of use
                                </p><br />
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </div>
                </Carousel.Item>
                <Carousel.Item>
                <div className="col-md-3 ">
                    <Card className="custom-card" id="dis-card" >
                        <Card.Img variant="top" src={img2} alt="reviewer2"/>
                        <Card.Body>

                            <Card.Text>
                                <p>
                                    "Looking for a customer engagement solution? Choose Brandwise. Their
                                    campaigns have gotten us <span> great responses</span> from our customers, and with the
                                    platform's insights, we've <span> effectively</span> been able to <span> target and retarget</span> customers"
                                </p><br />
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </div>
                </Carousel.Item>
                <Carousel.Item>
                <div className="col-md-3 ">
                    <Card className="custom-card" id="dis-card" >
                        <Card.Img variant="top" src={img3} alt="reviewer3"/>
                        <Card.Body>

                            <Card.Text>
                                <p>
                                    Brandwise Revolutionized our <span>Consumer Engagement </span>
                                    Strategy, enabling us to increase our store footfall and
                                    sales. We are very impressed with the <span>dynamic landing
                                        pages</span>, diverse <span>campaign options</span> and ease of use
                                </p><br />
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </div>
                </Carousel.Item>
                <Carousel.Item>
                <div className="col-md-3 ">
                    <Card className="custom-card" id="dis-card" >
                        <Card.Img variant="top" src={img5} alt="reviewer4" />
                        <Card.Body>

                            <Card.Text>
                                <p>
                                    "Brandwise has Revamped our campaigns! <span> Seamlessly
                                        directing customers to desired landing pages</span> with QR
                                    Codes, reaching <span> high intent audiences,</span> and effortlessly
                                    <span> tracking performance</span> is just <span> priceless</span>."
                                </p><br />
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </div>
                </Carousel.Item>
                <Carousel.Item>
                <div className="col-md-3 ">
                    <Card className="custom-card" id="dis-card" >
                        <Card.Img variant="top" src={img4} alt="reviewer5" />
                        <Card.Body>

                            <Card.Text>
                                <p>
                                    "Brandwise is our <span> one-stop solution</span>. Its capabilities to
                                    <span> digitize</span> equipment, accurately track and <span> geofence</span> products,and <span>simplify
                                        warranty registrations</span> have been <span>impressive and a delight to work with</span>."
                                </p><br />
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </div>
                </Carousel.Item>    
            </Carousel>
            </div>
            <div className="container feedback-content1" >
                <div className="slider-container">
                    <Slider {...settings} className='d-none d-md-flex' >
                        <div className="slide">
                            <div className="slide-content">
                                <div className="row">
                                    <div className="col-md-6 order-md-2">
                                        <img src={img1} alt="Reviewers1" className="slide-image" />
                                    </div>
                                    <div className="col-md-6 order-md-2 card-cont">
                                        <div className="card">
                                            <div className="card-content content1">
                                                <p>
                                                    Brandwise Revolutionized our <span>Consumer Engagement </span>
                                                    Strategy, enabling us to increase our store footfall and
                                                    sales. We are very impressed with the <span>dynamic landing
                                                        pages</span>, diverse <span>campaign options</span> and ease of use
                                                </p><br />
                                                <div className="row">
                                                    <div className="col-md-6 sm-6">
                                                        <div className="card">
                                                            <div className="card-content content2">
                                                                <div className="icon-text-container">
                                                                    <FaLongArrowAltUp style={{ fontSize: '25px', color: 'green', height: '40px', }} />
                                                                    <span className="text" style={{ fontWeight: "800", marginLeft: "-20px" }}>600%</span>
                                                                </div>
                                                                <p style={{ textAlign: 'center', fontSize: "14px" }}>Increase In Store Footfall</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 sm-6 " >
                                                        <div className="card">
                                                            <div className="card-content content2">
                                                                <div className="icon-text-container">
                                                                    <FaLongArrowAltDown style={{ fontSize: '25px', color: 'green', height: '40px' }} />
                                                                    <span className="text" style={{ fontWeight: "800", marginLeft: "-20px" }}>400%</span>
                                                                </div>
                                                                <p style={{ textAlign: 'center', fontSize: "14px" }}>Reduction in CAC</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide">
                            <div className="slide-content">
                                <div className="row">
                                    <div className="col-md-6 order-md-2">
                                        <img src={img2} alt="Reviewers2" className="slide-image" />
                                    </div>
                                    <div className="col-md-6 order-md-2 card-cont">
                                        <div className="card">
                                            <div className="card-content content1">
                                                <p>
                                                    "Looking for a customer engagement solution? Choose Brandwise. Their
                                                    campaigns have gotten us <span> great responses</span> from our customers, and with the
                                                    platform's insights, we've <span> effectively</span> been able to <span> target and retarget</span> customers"
                                                </p><br />
                                                <div className="row">
                                                    <div className="col-md-6 order-md-2" >
                                                        <div className="card">
                                                            <div className="card-content content2">
                                                                <div className="icon-text-container">
                                                                    <FaLongArrowAltUp style={{ fontSize: '25px', color: 'green', height: '40px' }} />
                                                                    <span className="text" style={{ fontWeight: "800", marginLeft: "-30px" }}>8x</span>
                                                                </div>
                                                                <p style={{ textAlign: 'center', fontSize: "14px" }}>Increase In Store Footfall</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 order-md-2" >
                                                        <div className="card">
                                                            <div className="card-content content2">
                                                                <div className="icon-text-container">
                                                                    <FaLongArrowAltUp style={{ fontSize: '25px', color: 'green', height: '40px' }} />
                                                                    <span className="text" style={{ fontWeight: "800", marginLeft: "-30px" }}>3x</span>
                                                                </div>
                                                                <p style={{ textAlign: 'center', fontSize: "14px" }}>Increase in Re-purchase</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide">
                            <div className="slide-content">
                                <div className="row">
                                    <div className="col-md-6 order-md-2">
                                        <img src={img3} alt="Reviewers3" className="slide-image" />
                                    </div>
                                    <div className="col-md-6 order-md-2 card-cont">
                                        <div className="card">
                                            <div className="card-content content1">
                                                <p>
                                                    "Brandwise has Revamped our campaigns! <span> Seamlessly
                                                        directing customers to desired landing pages</span> with QR
                                                    Codes, reaching <span> high intent audiences,</span> and effortlessly
                                                    <span> tracking performance</span> is just <span> priceless</span>."
                                                </p><br />
                                                <div className="row">
                                                    <div className="col-md-6 order-md-2" >
                                                        <div className="card">
                                                            <div className="card-content content2">
                                                                <div className="icon-text-container">
                                                                    <FaLongArrowAltUp style={{ fontSize: '25px', color: 'green', height: '40px' }} />
                                                                    <span className="text" style={{ fontWeight: "800", marginLeft: "-20px" }}>25%</span>
                                                                </div>
                                                                <p style={{ textAlign: 'center', fontSize: "14px" }}>Increase In Immediate Sales</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 order-md-2" >
                                                        <div className="card">
                                                            <div className="card-content content2">
                                                                <div className="icon-text-container">
                                                                    <FaLongArrowAltUp style={{ fontSize: '25px', color: 'green', height: '40px' }} />
                                                                    <span className="text" style={{ fontWeight: "800", marginLeft: "-20px" }}>30%</span>
                                                                </div>
                                                                <p style={{ textAlign: 'center', fontSize: "14px" }}>Increase in daily store Footfall</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide">
                            <div className="slide-content">
                                <div className="row">
                                    <div className="col-md-6 order-md-2">
                                        <img src={img5} alt="Reviewers4" className="slide-image" />
                                    </div>
                                    <div className="col-md-6 order-md-2 card-cont">
                                        <div className="card">
                                            <div className="card-content content1">
                                                <p>
                                                    "Brandwise's authentication solution is highly rated for
                                                    <span>security and reliability</span>. Our experience has been superb with <span> 100% uptime</span>, deploying
                                                    Digital Document Authentication <span> across all our certification programs</span> for
                                                    professionals and students."
                                                </p><br />
                                                <div className="row">
                                                    <div className="col-md-6 order-md-2" >
                                                        <div className="card">
                                                            <div className="card-content content2">
                                                                <div className="icon-text-container">

                                                                    <span className="text" style={{ fontWeight: "800" }}>100%</span>
                                                                </div>
                                                                <p style={{ textAlign: 'center', fontSize: "14px" }}>GS1 Compliant</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 order-md-2" >
                                                        <div className="card">
                                                            <div className="card-content content2">
                                                                <div className="icon-text-container">

                                                                    <span className="text" style={{ fontWeight: "800", }}>3000</span>
                                                                </div>
                                                                <p style={{ textAlign: 'center', fontSize: "14px" }}>New Customers Added to CRM</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide">
                            <div className="slide-content">
                                <div className="row">
                                    <div className="col-md-6 order-md-2">
                                        <img src={img4} alt="Reviewers5" className="slide-image" />
                                    </div>
                                    <div className="col-md-6 order-md-2 card-cont">
                                        <div className="card">
                                            <div className="card-content content1">
                                                <p>
                                                    "Brandwise is our <span> one-stop solution</span>. Its capabilities to
                                                    <span> digitize</span> equipment, accurately track and <span> geofence</span> products,and <span>simplify
                                                        warranty registrations</span> have been <span>impressive and a delight to work with</span>."
                                                </p><br />
                                                <div className="row">
                                                    <div className="col-md-6 order-md-2" >
                                                        <div className="card">
                                                            <div className="card-content content2">
                                                                <div className="icon-text-container">

                                                                    <span className="text" style={{ fontWeight: "800" }}>100%</span>
                                                                </div>
                                                                <p style={{ textAlign: 'center', fontSize: "14px" }}>GS1 Compliant</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 order-md-2" >
                                                        <div className="card">
                                                            <div className="card-content content2">
                                                                <div className="icon-text-container">
                                                                    <FaLongArrowAltUp style={{ fontSize: '25px', color: 'green', height: '40px' }} />
                                                                    <span className="text" style={{ fontWeight: "800", marginLeft: "-30px" }}>55%</span>
                                                                </div>
                                                                <p style={{ textAlign: 'center', fontSize: "14px" }}>Customer Warranty Registration</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div><br /><br /><br /><br />
            </div>
        </div>
    )
}