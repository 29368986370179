import React from "react";
import "./topHead.css";

function TopHead() {
	return (
		<div style={{background: "linear-gradient(0.63deg, rgba(49, 87, 239, 0.1) 1.58%, rgba(49, 87, 239, 0) 97.39%)", textAlign: '-webkit-center'}}>
			<div className="container blogtophead">
				<div className="row">
						<h1>Blogs</h1>
						<h4 className="px-6">Here is a guide to our most feature blogs on Anti-Counterfiting Solution, Product Digitization, and Consumer Digital Engagement on Brandwise<sup>®</sup> </h4>
				</div>
			</div>
		</div>
	);
}

export default TopHead;