import React, {useEffect}  from "react";
import TopHead from "../components/QsealKnowMore/tophead";
import Industry from "../components/QsealKnowMore/industry";
import Solution from "../components/QsealKnowMore/solution";
import Features from "../components/QsealKnowMore/features";
import Visual from "../components/QsealKnowMore/visual";
import Discover from "../components/Home/discover";
import Feedback from "../components/Home/feedback";
import FAQ from "../components/QsealKnowMore/faq";



export default function Qsealknowmore() {
    useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
    return (
      <div>
      <TopHead />
      <Industry />
      <Solution />
      <Features />
      <Visual />
      <Discover />
      <Feedback />
      <FAQ />
    </div>
    )
}