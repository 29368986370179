import React from 'react'
import Card from 'react-bootstrap/Card';
import img1 from '../../images/prod1.webp';
import img2 from '../../images/prod2.webp';
import img3 from '../../images/prod3.webp';
import img4 from '../../images/prod4.webp';
import img5 from '../../images/digi1.webp';
import img6 from '../../images/digi2.webp';
import img7 from '../../images/digi3.webp';
import img8 from '../../images/qreach4.png';
import img9 from '../../images/personal1.webp';
import img10 from '../../images/personal2.webp';
import img11 from '../../images/personal3.webp';
import img12 from '../../images/personal4.webp';
import img13 from '../../images/mean1.webp';
import img14 from '../../images/mean2.webp';
import img15 from '../../images/mean3.webp';
import img16 from '../../images/mean4.webp';
import './prod-digi.css';

import 'bootstrap/dist/css/bootstrap.min.css';

export default function prodDigi() {
    return (
        <div className="product">
            <div className="container prod-digi-content">
                <div className="d-flex justify-content-between align-items-center" >
                    <h2>
                        <span>Product Digitization & Robust Authentication</span>
                    </h2>
                    <h2>
                        <span>QSeal</span>
                    </h2>
                </div>

                {/* Grid layout for larger screens only */}
                <div className=" row g-4">
                    <div className="col-md-3 ">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img1} alt="Counterfeit fashion products with a 'Fake' stamp, 
                            highlighting the importance of authenticity in retail and brand protection" title="Protecting brands from counterfeit products with authenticity in retail."/>
                            <div class="card-header">
                                <strong>Counterfeit Protection</strong>
                            </div>
                            <Card.Body className='product-body'>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Protect Customers form Fake
                                    Products that Damages Image
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 ">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img2} alt="Icon of a handshake and shield representing trust, security, and partnership 
                            in business relationships, emphasizing faith" title="Win customer trust with advanced authentication & digitization."/>
                            <div class="card-header">
                                <strong>Win Customer’s Trust</strong>
                            </div>
                            <Card.Body className='product-body'>
                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Create Reliability and Faith
                                    within your Industry
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 ">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img3} alt="Real time data and insights collected by Brandwise for the admin to 
                            understand customer behavior towards their products" title=" Real-time insights to understand customer behavior."/>
                            <div class="card-header">
                                <strong>Interaction Data in Real Time</strong>
                            </div>
                            <Card.Body className='product-body'>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Understand Customer’s
                                    Behavior towards your
                                    Products
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img4} alt="Effortless visualization of your supply chain to track each batch’s journey in 
                            transit. Achieved through digitization." title="Digitized supply chain tracking for batch journeys."/>
                            <div class="card-header">
                                <strong>Supply Chain Traceability</strong>
                            </div>
                            <Card.Body className='product-body'>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Digitize Products to Monitor
                                    each batch’s journey in transit
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div class="product-know-more-button">
                        <a href="/qsealknowmore">
                            <button class="know-more-button">Know More</button>
                        </a>
                    </div>
                </div><br /><br /><br />
                <div className="d-flex justify-content-between align-items-center">
                    <h2>
                        <span>Immersive Customer Engagement & Digital
                            Experiences</span>
                    </h2>
                    <h2>
                        <span>QReach</span>
                    </h2>
                </div>
                <div className="row g-4">
                    <div className="col-md-3">
                        <Card className="custom-card">

                            <Card.Img variant="top" src={img5} alt="Create personalized, customized and relevant marketing campaigns to deliver memorable 
                            customer experiences that boost engagement." title="Personalized campaigns for better customer engagement."/>
                            <div class="card-header">
                               <strong>16+ Campaign Options</strong> 
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Create relevant, personalized
                                    & customized experiences
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img6} alt="Customer data and profiling that helps to engage and understand consumer 
                            behavior for improved campaign performance" title="Customer profiling to improve campaign performance."/>
                            <div class="card-header">
                                <strong>Customer Insights & Analytics</strong>
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Understand, Interact & Engage
                                    with Every Customer
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 ">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img7} alt="Access, build and manage real time action alerts from Brandwise through a 
                            single API that helps you take swift action." title="Manage real-time action alerts through a single API."/>
                            <div class="card-header">
                            <strong>Real Time Action Alerts</strong>
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Build, manage & send
                                    transaction alerts through a
                                    single API
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img8} alt="Customer engaging with omnichannel marketing across digital and physical platforms for 
                            enhanced interaction and seamless brand experience" title="Omnichannel marketing for seamless customer interaction."/>
                            <div class="card-header">
                                <strong>Omnichannel Communication</strong>
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Personalize & Connect on
                                    multiple impactful channels
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div class="product-know-more-button">
                        <a href="/qreachknowmore">
                            <button class="know-more-button">Know More</button>
                        </a>
                    </div>
                </div><br /><br />

                <div className="d-flex justify-content-between align-items-center">
                    <h2>
                        <span>Personalized Story Telling & Loyalty Programs</span>
                    </h2>

                </div>
                <div className=" row g-4">
                    <div className="col-md-3 ">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img9} alt="Delivering relevant customer experiences at every stage with 
                            insightful and accurate customer journey mapping" title="Accurate journey mapping for relevant customer experiences"/>
                            <div class="card-header">
                                <strong>Customer Journey Mapping</strong>
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Deliver relevant customer
                                    experiences at every stage
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img10} alt="Customers enjoying personalized experiences providing good 
                            reviews which in turn translates to brand loyalty" title="Personalized experiences driving reviews and loyalty." />
                            <div class="card-header">
                                <strong>Long Term Loyalty</strong>
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Reduce Churn by sending
                                    tailored offers, coupons, gift
                                    cards, etc.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 ">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img11} alt="Attract customers with seamless post sale experiences so that your 
                            brand can have satisfied buyers coming back for more" title='Seamless post-sale experiences for repeat customers.'/>
                            <div class="card-header">
                               <strong> Sustainable Retention</strong>
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Relieve Customer from Post
                                    Sale Challenges, Let Brandwise
                                    Take Care of it!
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img12} alt="Increase revenue by expanding wallet share per customer. 
                            Reactivate dormant customers and increase spend." title="Increase revenue by reactivating and engaging customers."/>
                            <div class="card-header">
                               <strong> Expand Wallet Share</strong>
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Reactivate dormant customers
                                    and Increase customer spend
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div><br /><br /><br />
                <div className="d-flex justify-content-between align-items-center">
                    <h2>
                        <span>Meaningful Analytics & Rewarding Insights</span>
                    </h2>

                </div>
                <div className=" row g-4">
                    <div className="col-md-3 ">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img13} alt="Customer choosing between two buying options, where Brandwise gathers 
                            reliable data on customer preferences" title="Reliable data on customer preferences for better decisions."/>
                            <div class="card-header">
                                <strong>Accurate Buyer Behavior</strong>
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Gather realistic and reliable
                                    data on customer preferences
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img14} alt="Data Dashboard to manage and track multiple campaign 
                            performances at once to determine ROI, Growth and more." title="Data dashboard to track campaign performance and ROI."/>
                            <div class="card-header">
                                <strong>Manage Campaign ROI Data</strong>
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Mange and track multiple
                                    campaign performances at
                                    once
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img15} alt="Study data that is tailored to meet your specific needs on Brandwise Dashboards to get a 
                            clear overview that matters to you" title="Tailored study data on Brandwise dashboards for clear insights."/>
                            <div class="card-header">
                                <strong>Data That Matter To You</strong>
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Study the data that is tailored
                                    to your specific needs
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img16} alt="brand executive enjoys a simple and user friendly UI that helps getting 
                            work done effortlessly and efficiently." title='Brand executive using user-friendly UI for efficient work.' />
                            <div class="card-header">
                                <strong>Effortless & User Friendly</strong>
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Simple yet powerful UI so that
                                    you can get the most,
                                    effortlessly
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div><br /><br /><br />
            </div><br /><br /><br />
        </div>
    )
}
