import React from 'react';
import img1 from "../../images/Footwear 2.webp";
import img2 from "../../images/Footwear 3.webp";
import img3 from "../../images/Footwear 4.webp";
import img4 from "../../images/Footwear 5.webp";
import './boost.css';

export default function Seamless() {
	return (
		<div className="container boost-content">
        <div className="row">
            <div className="col-md-5 order-md-2">
                <div className="footwear-content2">
                  <h2><span>Boost</span> Store Revenue</h2>
                  <p>Drive foot-fall through shoppable ads. From store-front
                     engagements to print and social media. Maximize your
                     marketing ROI by enabling customers to effortlessly scan
                     QR codes and engage through suite of Brandwise
                     campaigns on all channels.</p>
                    <div className='list-content'>
                   <ul>
                      <li>Increased in store walk-ins by *8x</li>
                      <li>*3x Increase in Repeat Purchases</li>
                      <li>*40% Decrease in Customer Acquisition Cost</li>
                      <li>Leverage dynamic coupons – *3x higher conversions</li>
                      <li>Up to 100x ROI on SM channels</li>
                   </ul>
                   </div>
                </div>
            </div>
            <div className="col-md-1 order-md-2"></div>
            <div className="col-md-6 order-md-2 " style={{textAlign:"center"}}>
                <img src={img1} alt="Two business executives looking at statistics showing the increased store walkins, 
                total conversions and increase in revenues" style={{width:'100%',height:"95%"}} title="Analyze statistics on store walk-ins and revenue." />   
            </div>
            </div><br></br><br></br>
            <div className="row">
                <div className="col-12 col-md-6 order-2 order-md-1" style={{textAlign:"center"}}>
                    <img src={img2} alt="Customer engaging with omnichannel marketing across digital and physical platforms for enhanced 
                    interaction and seamless brand experience" style={{width:'100%',height:"90%"}} title="Customer engaging with seamless omnichannel marketing." />
                </div>
                <div className="col-12 col-md-1 order-1 order-md-2"></div>
                <div className="col-12 col-md-5 order-1 order-md-2">
                    <div className="footwear-content2" >
                        <h2><span> Attract,
                        Engage, Convert</span> & <span>Retain</span></h2>
                        <p>Seamlessly grow and expand your customer base
                           with a range of attractive, exciting and impactful
                           marketing campaigns. Understand your customer’s
                           journey and deliver personalized pipelines to attract,
                           engage, convert and retain your valuable customers</p>
                        <div className='list-content'>
                        <ul>
                            <li>16+ Channels</li>
                            <li>BYC (Build Your own Campaigns)</li>
                            <li>Online, Offline and omnichannel campaigns</li>
                            <li>Dynamic content updates</li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div><br></br><br></br>
            <div className="row">
            <div className="col-md-5 order-md-2">
                <div className="footwear-content2">
                  <h2>Lock counterfeits, unlock 
                  <span> business growth</span></h2>
                  <p>Leverage most advanced authentication solution powered by Cryptoblocks, AI and app-less consumer
                     experience.</p>
                  <p>Deliver warranty registration at unit level, bring remote 
                    consumers into conversation and build everlasting loyalty 
                    with your remote Fanclub.</p>
                  <p>Get compliance to sustainability initiatives such as DPP,
                  dynamic digital content and minimize materials waste by 
                  plug-in recycle partner services.</p>
                   
                </div>
            </div>
            <div className="col-md-1 order-md-2"></div>
            <div className="col-md-6 order-md-2" style={{textAlign:"center"}}>
            <img src={img3} alt="Customer using Brandwise QSeal to authenticate the shoe by simply scanning a 
            QR Code for a hassle free experience" style={{width:'100%',height:"90%",marginTop:"20px"}} title="Customer authenticating shoes via QR code scan." />
            </div>
            </div><br></br><br></br>
            <div className="row">
                <div className="col-12 col-md-6 order-2 order-md-1" style={{textAlign:"center"}}>
                    <img src={img4} alt="Admin viewing robust data insights in real time to measure performance and customer 
                    engagement on various marketing campaigns" loading="lazy" style={{width:'100%',height:"85%",marginTop:'25px'}} title=" Admin viewing real-time marketing campaign insights." />
                </div>
                <div className="col-12 col-md-1 order-1 order-md-2"></div>
                <div className="col-12 col-md-5 order-1 order-md-2">
                    <div className="footwear-content2">
                    <h2>Detailed <span>Reports</span> and
                        Actionable <span>Insights</span></h2>
                        <p>Brandwise delivers robust data insights in real-time,
                           Measure each consumer engagement either on
                           products or through campaigns. Leverage ROI tools
                           to analyze and anticipate each channel performance 
                           by connecting dots across your initiatives.</p>
                        <div className='list-content'>
                        <ul>
                            <li>Advanced Segmentation, automated re-targeting</li>
                            <li>Privacy first approach with global compliance</li>
                            <li>Realtime insights, accurate profiling</li>
                            <li>Customer-centric experience delivery to
                            customers</li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div id="useContent"></div>
        </div>
    )
}