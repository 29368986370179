import React from "react";
import img from "../../images/Blogpost Digitization.svg";
import img2 from "../../images/Cteam1.png";
import "./blogcontent.css";

function BlogContent() {
	return (
		<>
			<div className="position-relative">
				<div className="container blog-content">
					<p>
						<span className="big-letter">C</span>onnected consumer and digital savvy markets demanding new age experiences, smart brands leveraging the technology to leap ahead the competition. Millions and billions of products are getting connected to the internet and coming to life in consumer hands. Advancements in the technology made the mission more exciting and rewarding. Accessible Cloud infrastructure, smart AI models, affordable internet and ubiquitous smartphones transforming remote consumer experience, while building the business intelligence for the next leap of revenue growth.
					</p>
					<h2 className="fw-bold">1. Looking into the history</h2>
					<p>In the modern era, a need has arisen to communicate securely with people and organisations we do not know and with whom we cannot easily share secret keys, said Dr Manulis. This need has given rise to public-key cryptography i.e asymmetric encryption. Public-key cryptography involves a pair of keys known as public key and private key. The owner of keys can be individual person, Brand, entity, website, or gadget. The message encrypted with one key can be opened – decrypted- by other corresponding key of the pair.</p>
					<div className="p-1 p-md-4 img-wrap position-relative">
						<div className="blue-div"></div>
						<img className="main-img" src={img} alt="Illustration of Brand’s Journey, from product digitization to customer experience, personalization, 
						sales growth and customer retention."  title="Illustration of brand journey: digitization, personalization, growth, and retention."/>
					</div>
					<p>
						Experiences overtaking the product and price. Business focus is being shifted from selling products to experiences in the age of remote life and metaverse. The truth is customer retention is 5 to 25 times cheaper than cost of acquisition. Digitization brings businesses and consumers together as a community, to take a mutually rewarding journey. Gone the days where business was worried about the consumer howl, the tables has turned, consumer simply switches the brand and preferences rather than following on the negative experiences if any. For businesses, gaining certain consumer feedbacks in direct channel is more valuable than the SM channels (If you like it tell others, else to us).
						<br />
						<br />
						Digitization is not only about delivering the consumer a delightful digital experience, but also for the internal business functions and the external stakeholders, with productive approaches and seamless integrations.
					</p>
					<h2 className="fw-bold">What is Product Digitization</h2>
					<p>Many smart devices are interacting directly with consumers and learning about individual preferences and adapting to their conveniences, to deliver the personalized experience. When it comes to physical products this additional intelligence layer comes from the digitization to turn the products smart. Product digitization is a method of adding unique digital capabilities to physical products, such that buyers can seamlessly interact with products through digital media and access dynamic information in Realtime. Which magnifies the business ability to influence consumer with lasting expediencies while acquiring rewarding growth insights. Retaining the buyers and expanding the wallet share is one of the prime focus area of the product digitization.</p>
					<h2 className="fw-bold">Key benefits of digitization</h2>
					<ul>
						<li>
							<span>Unit level Unique digital IDs (Not just at SKU or batch level)</span>
						</li>
						<li>
							<span>Open to public authentication (Trust and transparency) prevent counterfeits</span>
						</li>
						<li>
							<span>Economical way to gain consumer insights and build growth insights</span>
						</li>
						<li>
							<span>Dynamic data and lasting experience delivery through products</span>
						</li>
						<li>
							<span>Distributor and destination market management</span>
						</li>
						<li>
							<span>Post-sale experience, warranty registration, and marketing automation </span>
						</li>
						<li>
							<span>Seamless Consumer Relationship Management (Custom CRM)</span>
						</li>
						<li>
							<span>Consumer campaign automation and feedback collection</span>
						</li>
						<li>
							<span>Minimize print media, conserve *material energy, and contribute to environment and much more</span>
						</li>
					</ul>
					<p>
						To learn more use cases and customization options contact our expert team.
					</p>
					<h2 className="fw-bold">Digitization Approach and Budgeting </h2>
					<p>
						Digitization journey starts at companies digital vision and lands in business transformation. With latest cloud, AI, ML and Blockchain technologies the solutions turned to be more flexible and efficient at any scale and geography. Almost every business is digitally transforming, India is leading the digital transformation initiatives across developing countries - McKinsey report
					</p>
					<p>
						Ciphercode in collaboration with its customers has developed several budgeting models with an objective of 10X ROI. Contact us for custom and insightful proposition needed for your decision making and digital growth strategy. *Material energy – The Entry consumed by the materials from their production, transportation and till the recycling.
					</p>
					<hr />
					<div className="d-flex flex-row blog-author">
						<img src={img2} alt="profile_pic" className="rounded-circle"/>
						<div className="px-3 d-flex flex-row flex-wrap justify-content-between w-100">
							<div className="align-self-center" style={{minWidth: "224px"}}>
								<p className="fw-bold">Venkat Terugu</p>
								<p>Co-Founder at Ciphercode</p>
							</div>
							<div className="align-self-center" style={{paddingRight: "5vw", minWidth: "calc(110px + 5vw)"}}>
								<p className="fw-bold">Date</p>
								<p>28 Feb 2022</p>
							</div>
						</div>
					</div>
					<div className="mt-4">	
		              <div className="col-md-12" id="fbcomment">
		              <div className="fb-comments" data-href="https://ciphercode.co/blogpost/2" data-width="700"></div>
	 	            </div>
				</div>
				</div>
				<div className="blue-line hide-sm">
					<svg width="188" height="11" viewBox="0 0 188 11" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect x="-1" y="0.0644531" width="188.487" height="10.752" fill="#3157EF" />
					</svg>
				</div>
			</div>
		</>
	);
}

export default BlogContent;
