import React from "react";
import "./blogcontent.css";

import img1 from "../../images/med_img1.webp";
import img2 from "../../images/med_img2.webp";
import img4 from "../../images/Rohaan.png";

function BlogContent() {
    return (
        <>
            <div className="position-relative">
                <div className="container blog-content">
                    <h3>CDSCO Combating Counterfeit Medicine</h3>

                    <p>The Central Drugs Standard Control Organisation (CDSCO) is intensifying efforts
                        to combat counterfeit medicines in India by expanding its mandate for QR
                        codes on pharmaceutical packaging. Initially applied to the top 300 drug
                        brands, this requirement is set to encompass a broader range of products,
                        enhancing drug traceability and ensuring consumer safety.</p>

                    <h3>Understanding the CDSCO QR Code Mandate</h3>
                    <p>Effective August 1, 2023, the CDSCO requires manufacturers to include QR
                        codes on specified drug formulations. These codes must contain essential
                        information such as:</p>
                    <p>- Unique product identification code as for the CDSCO <u>Notification</u> 17th Nov.
                        2022</p>
                    <div className="row medicine-row" >
                        <div className="content-row col s12 m7">
                            <ul className="list-style">
                                <li>Unique product identification code;</li>
                                <li>Proper and generic name of the drug;</li>
                                <li>Brand name;</li>
                                <li>Name and address of the manufacturer;</li>
                                <li>Batch number;</li>
                                <li>Date of manufacturing;</li>
                                <li>Date of expiry; and</li>
                                <li>Manufacturing licence number.</li>
                            </ul>
                            <p>
                                This initiative aims to enhance the authenticity and traceability of medicines,
                                thereby protecting public health.
                            </p>
                        </div>
                        <div className="image-row col s12 m5">
                            <img className="body_image" src={img1} alt="Medicine Traceability" title="Medicine Traceability" />
                        </div>
                    </div>


                    <h3>Consequences of Non-Compliance</h3>
                    <p>While specific penalties for non-compliance are not detailed, the CDSCO has
                        previously taken action against manufacturers failing to meet quality standards,
                        including product recalls. Non-adherence to the QR code mandate could result
                        in similar enforcement measures, emphasizing the importance of compliance
                        for all pharmaceutical manufacturers.</p>

                    <h3>Advantages of QR Codes Over Traditional Barcodes</h3>
                    <p>QR codes offer several benefits compared to traditional barcodes, making them
                        more suitable for pharmaceutical applications:</p>

                    <ul className="list-style">
                        <li ><span>Higher Data Capacity</span>: QR codes can store extensive information, aligning
                            with CDSCO's comprehensive data requirements.</li>
                        <li ><span>Space Efficiency</span>: Their compact design fits well on limited packaging spaces.</li>
                        <li ><span>Flexible Scanning</span>: QR codes can be scanned very easily, enhancing usability
                            in various settings.</li>
                        <li ><span>Adaptability</span>: They can accommodate additional data fields, supporting
                            future regulatory requirements.</li>

                    </ul>
                    <p>These features make QR codes a robust solution for ensuring drug authenticity
                        and compliance.</p>

                    <h3>Implementing QR Codes with Brandwise</h3>
                    <p>For pharmaceutical companies, integrating QR codes into existing packaging
                        processes may seem challenging. Brandwise offers solutions to streamline this
                        transition, ensuring compliance with CDSCO regulations while enhancing
                        operational efficiency.</p>
                    <p>By adopting Brandwise technology, manufacturers not only comply with
                        regulatory mandates but also contribute to a safer pharmaceutical market,
                        fostering greater consumer trust and safeguarding public health.</p>

                    <div className="row">
                        <div className="col s12 ">
                            <img className="body_image" src={img2} alt=" " title="" />
                        </div>

                    </div>
                    <h3>Summary & Next Steps</h3>
                    <p>This is a commendable initiative by CDSCO, paving the way for a better society
                        and fostering a foundation for future innovations.
                        However, when it comes to ensuring product authenticity, relying solely on
                        serial numbers or back-end data may fall short. Advanced technologies like
                        encryption, AI-powered domain monitoring, and product digital certificates at
                        scale are the need of the hour.</p>

                    <p>Ciphercode.co has proudly pioneered the world’s first authentication
                        technology that redefines the limits of product verification. Premium brands
                        can leverage Ciphercode’s cutting-edge solutions to transcend the limitations of
                        simple QR codes and unlock unprecedented possibilities in product security
                        and trust.</p>


                    <div className="d-flex flex-row blog-author">
                        <img src={img4} alt="profile_pic" className="rouned-circle" style={{ height: "80px" }} />
                        <div className="px-3 d-flex flex-row flex-wrap justify-content-between w-100">
                            <div className="align-self-center" style={{ minWidth: "224px" }}>
                                <p className="fw-bold">Rohaan Joseph</p>
                                <p>Marketing Executive at Ciphercode </p>
                            </div>
                            <div className="align-self-center" style={{ paddingRight: "5vw", minWidth: "calc(110px + 5vw)" }}>
                                <p className="fw-bold">Date</p>
                                <p>03 January 2025</p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="col-md-12" id="fbcomment">
                            <div className="fb-comments" data-href="https://ciphercode.co/qrcodes_are_revolutionizing_pharma_industry" data-width="700" data-numposts=""></div>
                        </div>
                    </div>
                </div>
                <div className="blue-line hide-sm">
                    <svg width="188" height="11" viewBox="0 0 188 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="-1" y="0.0644531" width="188.487" height="10.752" fill="#3157EF" />
                    </svg>
                </div>
            </div>
        </>
    );
}
export default BlogContent;