import React,{useState} from 'react';
import "./footer.css";

function Footer() {
	const [email, setEmail] = useState("");
	const[error,seterror]=useState(false);

	async function subscribe(){
        let item = {email}
		if(email.trim("").length === 0 ){
			seterror(true)
		  }
		else{
		 seterror(false)
        fetch("https://web.ciphercode.co/api/v1/subscribe/", {
            method : 'POST',
            body: JSON.stringify(item),
            headers : {
                "Content-Type" : 'application/json',
                "Accept" : 'application/json',
            }
        })
        .then(response => response.json())
		.then(response => {
			if(response.message === "Your data successfully submitted"){
				alert('Thank you for subscribing with us')
				setEmail("")
			}
		}).catch((error)=>{
			console.log(error)
		})
    }
}
	return (
		<>
		<div className="py-4 contactus footer">
  <div className="p-5 text-center">
    <h2>
      Our newsletters and blogs have some nice content about <br />
      technology and the latest solutions.
    </h2>
    <p>Subscribe and we will deliver it right to your smart devices.</p>
    <div className="input-wrapper" style={{ position: 'relative', display: 'inline-block', textAlign: 'left' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <input
          type="email"
          name="email"
          placeholder="Enter your email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{
            flex: '1',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            width: '500px', // Increased width for the textbox
          }}
        />
        <button
          className="btn btn-primary"
          type="button"
          onClick={subscribe}
          style={{
            padding: '10px 20px',
            border: 'none',
            borderRadius: '4px',
            backgroundColor: '#007bff',
            color: '#fff',
            cursor: 'pointer',
          }}
        >
          Subscribe
        </button>
      </div>
      {error && (
        <p
          style={{
            color: 'red',
            marginTop: '5px',
            fontSize: '14px',
			textAlign:'left'
          }}
        >
          Please, fill in the email address
        </p>
      )}
    </div>
  </div>
</div>

		<div className="map">
		<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.856533768089!2d77.63589031424073!3d12.916940990891732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae148ec6e1418b%3A0x92109484f7044!2sIndiQube%20Delta!5e0!3m2!1sen!2sin!4v1651741918777!5m2!1sen!2sin"
        width="100%"
        height="350"
		title='location'
        style={{ border: "0" }}
        allowFullScreen
        loading="lazy">
		</iframe>
		</div>
		</>
	);
}

export default Footer;
