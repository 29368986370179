import React from "react";
import './usecaselist.css';
import img1 from "../../images/Personal Care.webp";
import img2 from "../../images/Footwear.webp";
import img3 from "../../images/retail_usecase.png";
import img4 from "../../images/Packaging.webp";
import img5 from "../../images/Home Appliances.webp";
import img6 from "../../images/Automobile.webp";



export default function usecaselist() {
    return (
       <>
        <div className="container usecaseslist">
			<div className="row">
			    <div className="col-12 col-md-5 order-md-2">
                    <div className="post-card">
                        <a href ="/personalcare" style={{textDecoration:'none'}}>
                        <div className="img">
                            <img src={img1} alt="blog_post" />	
                        </div>
                        <div className="title">Personal Care</div></a>
                        <div className="description">The personal care industry faces strict
                        regulations and growing demand for customer
                        personalization. Brandwise addresses these
                        challenges with tailored, brand-centric solutions
                        for compliance showcase, transparency, and
                        personalized customer engagement.</div>
                    </div>
                </div>
                <div className="col-12 col-md-2 order-md-2"></div>
                <div className="col-12 col-md-5 order-md-2">
                    <div className="post-card">
                        <a href ="/footwear" style={{textDecoration:'none'}}>
                        <div className="img">
                            <img src={img2} alt="blog_post" />	
                        </div>
                        <div className="title">Footwear</div></a>
                        <div className="description">The footwear industry faces high competition,
                        low loyalty, fragmented shopping journeys,
                        limited personalization & counterfeits. Brandwise
                        addresses these challenges with tailored
                        solutions. Brand-centric unified platform not only
                        address present challenges but opens new doors</div>
                    </div>
                </div>
            </div><br></br><br></br>
            <div className="row">
                <div className="col-12 col-md-5 order-md-2">
                    <div className="post-card">
                        <a href ="/retail" style={{textDecoration:'none'}}>
                        <div className="img">
                            <img src={img3} alt="blog_post" />	
                        </div>
                        <div className="title">Retail</div></a>
                        <div className="description">The retail industry faces channel disconnection,
                        underutilized data, and fragmented systems.
                        Brandwise solves these with omnichannel
                        integration and unified approach, empowering
                        brands to build consumer trust and build
                        intelligence across channels and touch-points.</div>
                    </div>
                </div>
                <div className="col-12 col-md-2 order-md-2"></div>
                <div className="col-12 col-md-5 order-md-2">
                    <div className="post-card">
                        <a href ="/packaging" style={{textDecoration:'none'}}>
                        <div className="img">
                            <img src={img4} alt="blog_post" />	
                        </div>
                        <div className="title">Packaging</div></a>
                        <div className="description">The packaging industry faces challenges like eco-
                        friendly materials, smart technologies, high
                        costs, and brand protection gaps. Brandwise
                        addresses these with tailored, brand-centric
                        solutions for sustainability, security, and
                        innovative customer engagement.</div>
                    </div>
                </div>
            </div><br></br><br></br>
            <div className="row">
                <div className="col-12 col-md-5 order-md-2">
                    <div className="post-card">
                        <a href ="/homeappliances" style={{textDecoration:'none'}}>
                        <div className="img">
                            <img src={img5} alt="blog_post" />	
                        </div>
                        <div className="title">Home Appliances</div></a>
                        <div className="description">The home appliance industry is facing unique
                        challenges, including the need for robust after-
                        sales services, building brand loyalty in a
                        competitive market, and ensuring regulatory
                        compliance, particularly with data privacy.
                        Brandwise offers tailored solutions to address</div>
                    </div>
                </div>
                <div className="col-12 col-md-2 order-md-2"></div>
                <div className="col-12 col-md-5 order-md-2">
                    <div className="post-card">
                        <a href ="/automobile" style={{textDecoration:'none'}}>
                        <div className="img">
                            <img src={img6} alt="blog_post" />	
                        </div>
                        <div className="title">Automobile</div></a>
                        <div className="description">Brandwise boosts brand influence, post-sale
                        engagement, and upselling opportunities in the
                        automobile industry with omnichannel solutions,
                        loyalty programs, real-time insights, and combats
                        counterfeiting through advanced authentication
                        solutions.</div>
                    </div>
                </div>
            </div>
        </div>
       </>
    )
}