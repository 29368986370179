import React from "react";
import './faq.css';
import img1 from "../../images/faq_img-transformed.webp";


export default function Solution() {
    const faqs = [
        { question: "What is QReach and how does it benefit my brand?", answer: "QReach helps brands connect with their audience through efficient marketing solutions." },
        { question: "Can QReach be integrated with existing CRMs?", answer: "Yes, QReach supports integration with most CRM platforms." },
        { question: "What types of campaigns can we run using QReach?", answer: "You can run email, SMS, and social media campaigns using QReach." },
        { question: "How does QReach help drive in-store footfall?", answer: "QReach uses targeted campaigns and offers to attract customers to stores." },
        { question: "Is QReach scalable for growing businesses?", answer: "Absolutely! QReach is designed to grow with your business." },
        { question: "How does QReach measure campaign success?", answer: "QReach provides detailed analytics and reporting for all campaigns." },
    ];
    return (
        <div className="container qreachknowmore-faqs">
            <h2>FAQs</h2>
            <div className="faqs-content">
                <div className="row">
                    <div className="col s12 m4">
                        <h3>Still Have<br />
                            Questions about<br />
                            QReach?</h3>
                        <img
                            src={img1}
                            alt=""
                            className="faq-image"
                        />
                    </div>
                    <div className="col s12 m5">
                    {faqs.map((faq, index) => (
                            <div key={index} className="faq-item">
                                <div className="faq-question">
                                    {faq.question}
                                    <div className="dropdown-icon-wrapper">
                                        <span className="dropdown-icon">▼</span>
                                    </div>
                                </div>
                                <div className="faq-answer">{faq.answer}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            
        </div>
        

    );
}