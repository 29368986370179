import React from "react";
import './solution.css';
import img1 from "../../images/qseal_more3.webp";


export default function Solution() {
    return (
        <div className="container qreachknowmore-solution">
            <div className="solution-content">
                <h2>The Solution</h2>
                <div className="row">
                    <div className="col-s12">
                        <div className="card" id="solution-card">
                            <div className="card-content" id="solution-cardcontent">
                                <h3>Let’s explore QSeal more</h3>
                                <p>An intelligent product digitization and security solution that not only delivers world-class anti-counterfeit features
                                    but also enables seamless remote consumer connections, fosters dynamic brand experiences, and strengthens
                                    consumer trust.</p>

                            </div>
                        </div>
                    </div>
                </div><br />
                <div className="row solution-row">
                    <div className=" solution-col1 col s12 m6">
                        <div className="solution-features">
                            <div className="feature-item ">
                                Public Product Authentication (Anti-Counterfeiting)
                            </div><br />
                            <div className="feature-item">
                                Enhanced Customer Trust and Loyalty
                            </div><br />
                            <div className="feature-item">
                                Seamless Customer engagement
                            </div><br />
                            <div className="feature-item">
                                Real-Time Ground Insights
                            </div>
                        </div>
                    </div>
                    <div className=" solution-col2 col s12 m6">
                        <img src={img1} className="sol-img" alt=" " />
                    </div>
                </div><br />
                <div className="row g-4">
                    <div className="col s12 m4">
                        <div className="card" id="metrics-card">
                            <div className="card-content" id="metrics-cardcontent">
                                <h4>60k+</h4>
                                <p><strong>Products Digitized</strong></p>
                            </div>
                        </div>
                    </div>
                    <div className="col s12 m4">
                        <div className="card" id="metrics-card">
                            <div className="card-content" id="metrics-cardcontent">
                                <h4>3M+</h4>
                                <p>

                                    <strong>Touch-points Delivered</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col s12 m4">
                        <div className="card" id="metrics-card">
                            <div className="card-content" id="metrics-cardcontent">
                                <h4> GS1</h4>
                                <p>
                                    <strong>Compliant</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>

    );
}