import React from "react";
import './tophead.css'
import img1 from "../../images/footwear_banner.webp";
import img2 from "../../images/qseal-hero-image.webp";

export default function TopHead() {
    return (
        <>
            <div className="tophead-view">
                <div className="pt-5">
                    <img className="cover-image" src={img1} alt="cover_pic" />
                </div>
                <div className="container qreachknowmore-tophead">
                    <div className="qreach-content">
                        <div className="row">
                            <div className="col-md-5 order-2 order-md-2 ">
                                <h1>Advanced <span>Product Authentication </span> & <span>Brand </span>Protection</h1>
                                <p>A cutting-edge product authentication solution powered by
                                    World’s 1st Cryptoblock technology. Designed to combat
                                    counterfeiting, QSeal ensures each product is uniquely
                                    verified, delivering unmatched security and building customer
                                    trust. With real-time tracking, advanced authentication, and
                                    seamless integration into your operations, QSeal safeguards
                                    your revenue and enhances your brand’s reputation in today’s
                                    competitive market.
                                </p>
                                <br></br>
                                <a href="/schedule_demo"><button type="button">GET DEMO</button></a>
                            </div>
                            <div className="col-md-7 order-1 order-md-2 " style={{ textAlign: "right" }}>
                                <img src={img2} alt="" style={{ width: "100%", height: "100%", marginTop:"25px" }} />
                            </div>
                        </div>
                    </div>
                    <br></br>
                </div>
            </div>
        </>
    );
}