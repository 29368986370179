import React, {useEffect} from "react";
import TopHead from "../components/Blognumberten/tophead";
import BlogContent from "../components/Blognumberten/blogcontent";
import { Helmet } from "react-helmet";

function Blog() {
	useEffect(() => {window.scroll({top: 0, behavior: 'smooth'})});
	return (
		<div>
			{/*<Helmet>
                <title>Safety First: How QR Codes are Revolutionizing Pharma Industry</title>
                <meta name="description" content="Shift from vanity metrics to revenue metrics. Discover how brands prioritize meaningful data and optimize marketing performance for success." />
                <meta name="keywords" content="Revenue Metrics, Vanity Metrics, Meaningful Data, Marketing Success" />
               
                <meta property="og:title" content="How Brands are Shifting from Vanity to True Revenue Metrics" />
                <meta property="og:description" content="Shift from vanity metrics to revenue metrics. Discover how brands prioritize meaningful data and optimize marketing performance for success." />
                <meta property="og:url" content="https://ciphercode.co/qrcodes_are_revolutionizing_pharma_industry" />
               
                <meta name="twitter:title" content="How Brands are Shifting from Vanity to True Revenue Metrics" />
                <meta name="twitter:description" content="Shift from vanity metrics to revenue metrics. Discover how brands prioritize meaningful data and optimize marketing performance for success." />
            </Helmet>*/}
			<TopHead />
			<BlogContent />
		</div>
	);
}
export default Blog;