import React from "react";
import './solution.css';
import img1 from "../../images/solution-img1.webp";


export default function Solution() {
    return (
        <div className="container qreachknowmore-solution">
            <div className="solution-content">
                <h2>The Solution</h2>
                <div className="row">
                    <div className="col-s12">
                        <div className="card" id="solution-card">
                            <div className="card-content" id="solution-cardcontent">
                                <h3>What is QReach?</h3>
                                <p>A customer engagement platform that enables businesses to connect with audiences through personalized
                                    campaigns, omnichannel integration, and real-time insights. It helps brands deliver tailored interactions,
                                    optimize marketing strategies, and build lasting customer relationships in a scalable and competitive way.</p>

                            </div>
                        </div>
                    </div>
                </div><br />
                <div className="row solution-row">
                    <div className=" solution-col1 col s12 m6">
                        <div className="solution-features">
                            <div className="feature-item ">
                                Personalized Customer Engagement
                            </div><br/>
                            <div className="feature-item">
                                Seamless Omnichannel Integration
                            </div><br/>
                            <div className="feature-item">
                                Actionable Real-Time Insights
                            </div><br/>
                            <div className="feature-item">
                                Real ROI Calculation
                            </div>
                        </div>

                    </div>
                    <div className=" solution-col2 col s12 m6">
                        <img src={img1} className="sol-img" alt=" " />
                    </div>
                </div><br/>
                <div className="row g-4">
                    <div className="col s12 m4">
                        <div className="card" id="metrics-card">
                            <div className="card-content" id="metrics-cardcontent">
                                <h4>200x</h4>
                                <h4>ROAS</h4>
                                
                                <p><strong>In Meta Ads</strong></p>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col s12 m4">
                        <div className="card" id="metrics-card">
                            <div className="card-content" id="metrics-cardcontent">
                            <h4><span className="arrow-up" aria-hidden="true">↑</span>3x</h4>
                                <p>
                                    
                                    <strong>Higher Customer Conversions</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col s12 m4">
                        <div className="card" id="metrics-card">
                            <div className="card-content" id="metrics-cardcontent">
                            <h4> <span className="arrow-down" aria-hidden="true">↓</span>70%</h4>
                <p>
                   
                    <strong>In Customer Acquisition Costs (CAC)</strong>
                </p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>

    );
}