import React from "react";
import img from "../../images/automobile_salesimg.png";
import "./customerEngagement.css";

export default function CustomerEngagement() {
	
	return (
		<div style={{background: "linear-gradient(180deg, rgba(128, 200, 243, 0.1)  0%, rgba(128, 200, 243, 0) 100%)"}}>
			<div className="container customer-engagement top-head">
				<div className="row">
					<div className="col text-center align-self-center p-4 p-sm-0">
						<img className="blog_image" alt="Suggested blog" src={img} style={{width: "min(419.62px, 100%)", minWidth: "100px"}} />
					</div>
					<div className="col">
						<div>
							<h1>Reduce counterfeit product sales</h1>
							<h4>Qseal<sup>®</sup> our cloud-based product authentication, and deep trace solution maps each unique product detail including serial number & distribution details into a QR code secured using an online digital certificate. Making it almost impossible for counterfeiters to use such details in counterfeit products.</h4>
						</div>

						<a rel="learnmore" href="/product">                       
						<button className="btn btn-BlueBg">Learn More</button>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

