import React from 'react';
import img1 from "../../images/footwearimg1.webp";
import img2 from "../../images/Footwear 7.webp";
import img3 from "../../images/retail8.webp";
import './brand.css';

export default function Brand() {
  return (
    <div className="container brand-content" id="brandContent">
      <h2>
        How the Retail Industry leverages <span>Brandwise</span> . . .
      </h2>
      <div className="row g-4">
        {/* Card 1 */}
        <div className="col-md-4">
          <div className="card custom-card-retail">
            <img
              src={img1}
              alt="Women going on a shopping spree excited for more experiences, offers and sales"
              loading="lazy"
              className="card-img-top"
              title="Customers excited about shopping experiences, offers, and sales."
            />
            <div className="card-body">
              <h3 className="card-title">Drive Store Footfall to New Heights</h3>
              <p className="card-text">
                Utilize proven 16+ dynamic engagement channels to boost store
                footfall and enhance conversion rates. Seamlessly integrate
                offline-to-online (O2O), bring diverse visitors from wider
                platforms. Deliver and accept omnichannel coupons, and measure
                growth performance.
              </p>
            </div>
          </div>
        </div>

        {/* Card 2 */}
        <div className="col-md-4">
          <div className="card custom-card-retail">
            <img
              src={img2}
              alt="Customer enjoying seamless omnichannel experiences, empowering brands to build strong connections and drive loyalty across platforms"
              loading="lazy"
              className="card-img-top"
              title="Seamless omnichannel experiences building brand loyalty."
            />
            <div className="card-body">
              <h3 className="card-title">One Stop Platform for All Needs</h3>
              <p className="card-text">
                Brandwise not only has a rich set of tools, but it also comes
                with pre-loaded ecosystem integrations such as POS, Shopify,
                CRM, WhatsApp, Meta campaigns, and more. The unified platform
                relieves you from pinpoints & lets you focus on your core
                business.
              </p>
            </div>
          </div>
        </div>

        {/* Card 3 */}
        <div className="col-md-4">
          <div className="card custom-card-retail">
            <img
              src={img3}
              alt="Customer feeling secure about trusting the company with their data for improved customer experiences"
              loading="lazy"
              className="card-img-top"
              title="Customer trusting company with data for enhanced experiences."
            />
            <div className="card-body">
              <h3 className="card-title">Build Trust and Collect Data...Safely</h3>
              <p className="card-text">
                Create appealing and inspiring interactions to collect user data
                exclusive to your business and services. Make customers part of
                your journey and build responsible data assets to empower future
                actions.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="feedbackContent"></div><br /><br /> <br />
    </div>
  );
}
