import React, {useEffect} from "react";
import "./terms&condition.css";
import whitelogo from "../images/favicon.png";
import { Helmet } from "react-helmet";

function Termscondition() {
    useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
    return (
        <>
        <Helmet>
        <title>Customer Engagement and Digitization Leading Solutions</title>
        <meta name="description" content="Cutting-edge solutions for customer engagement, product digitization and authentication. Boost brand loyalty, trust and sales with custom QR campaigns." />
        <meta name="keywords" content="Ciphercode, Brandwise, Customer Engagement, Product Digitzation, Authentication, Brand Loyalty" />
        <meta property="og:title" content="Customer Engagement and Digitization Leading Solutions" />
        <meta property="og:description" content="Cutting-edge solutions for customer engagement, product digitization and authentication. Boost brand loyalty, trust and sales with custom QR campaigns." />
        <meta property="og:url" content="https://ciphercode.co/privacy/" />
        <meta property="og:image" content={whitelogo} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Customer Engagement and Digitization Leading Solutions" />
        <meta name="twitter:description" content="Cutting-edge solutions for customer engagement, product digitization and authentication. Boost brand loyalty, trust and sales with custom QR campaigns." />
        </Helmet>
		<div className="terms-content">
            <div className="container">
            <h1>Privacy Policy</h1>
            <p>Ciphercode Tech Solutions Private Limited and its third-party service providers engaged to render certain services on the portal (if portallicable) (“Ciphercode”/“We”/ “Us”) are committed to upholding the privacy and security of the information supplied by every person (“User”/“You”) accessing and using any version of the Ciphercode portallication (“portal”) on a compatible device. This privacy policy (“Privacy Policy”) briefly provides the manner in which We collect and use User information. By accessing or using the portal, You signify that You have read, understood and agree to be bound by this Privacy Policy. We reserve the right to update or modify this Privacy Policy at any time without prior notice and such changes shall be effective immediately upon posting the updated or modified Privacy Policy on the Ciphercode website (i.e. https://ciphercode.co/) and we shall not be bound to inform you of any modifications hereof. Your access and use of the portal following any such change constitutes your agreement to follow and be bound by this Privacy Policy, as updated or modified. For this reason, we encourage You to review this Privacy Policy each time You access and use the portal. Your use of the portal and any disputes arising therefrom, is subject to this Privacy Policy as well as the terms and conditions, as set out below (“T&C”). Please read the T&C ( Term&Conditions) in order to obtain information on the terms governing the use of our portal and our services.</p>
            <h3>1. Information Collected:</h3>
            <p>We will collect personal information of the User. While some User information must be mandatorily provided, others are optional and certain portions of the information will remain private and some will be displayed to other users; We shall always let the User know which is which. Additional information may be gathered during subsequent use of the portal by the User, whenever the User chooses to provide it.</p>
             <ol className="d">
                 <li>Certain mandatory information will be collected at the time of registration on the portal which is required to enable the User to login to the portal and for Us to: (i) verify the User’s identity; (ii) provide user-specific services and (iii) safeguard against illegal activities like fraud, cheating, misportalropriation, etc.</li>
                 <li>In order to use the facilities and services available on the portal, the User may be required, from time to time, to provide certain additional personal information after registration on the portal, which information shall be collected only upon receiving the User’s express consent</li>
                 <li> We may also automatically receive and collect certain anonymous information in standard usage logs through the web server, including mobile-identification information obtained from the equivalent of “cookies” sent to the portal, including mobile network information, standard web log information, traffic to and from our portal, tracking inside the portal and any other available information, from: <br />an IP address, assigned to the device used by the User;
                 <br /> the domain server through which the User accesses the portal and the functions and features therein; and the type of device used by the User (“Device”).</li>
                 <li>The User may choose to provide Us with access to certain personal information stored by third parties like social networking sites (e.g. Facebook and Twitter). We will have access to such information to the extent allowed by the User’s privacy settings on that site and the User’s specific authorization. In the event the User associates a User account managed by Us with an account maintained with any third party to enable Us to access certain information maintained in such third party managed accounts, the User agrees that We may collect, store and use such information in accordance with this Privacy Policy.</li>
                 <li>After obtaining the User’s specific consent to allow the portal to access the User’s SMS inbox, we may collect relevant information from text messages (SMS) received by the Users from providers of services and/or products (including but not limited to retail outlets, financial institutions, mobile carriers and utility companies), that will enable us to provide better access to financial services and products to the Users. We will only access business messages that originate from alphanumeric senders.</li>
                 <li>In order to enhance our ability to provide valuable services and experiences to the User, We may: (i) automatically receive, collect and analyze your location information which may be accessed through a variety of methods including, inter alia, GPS, IP address, and cell tower location; and (ii) collect information pertaining to your Device and your usage thereof, including, inter alia,  the names of the other portallications on your mobile Device and how you use them, information about your Device, and information about your use of features or functions on your Device.</li>
             </ol>
             <h3>2.  Method and manner of use of User information.</h3>
             <ol className="d">
                 <li>When the User registers with the portal, We will use the information supplied by the User to enable with brand services user accepted for.</li>
                 <li> We will also use User information to enable activities and transactions that need to occur during the process of lending, such as:
                     <br /> Generating and maintaining User profiles on the portal. <br /> Provide brand buyback features.
                     <br /> Aiding financial services organizations to provide better services. <br /> Facilitating collection activities as needed.
                     <br /> Maintaining regular communications with the User. <br /> Modifying the portal from time to time to cater to the User’s interests.
                    <br /> Providing the portal and the functions and features therein, efficiently.
                    <br /> Preserve social history as governed by existing law or policy.
                 </li>
                 <li>
                 You acknowledge that you are licensing Us to use, modify, display, distribute and create new material from the information you provide through the portal to render certain services on the portal. By providing such information, you automatically agree or promise that the owner of such information has expressly agreed to allow or license, as the case may be, Us to use the information in the manner set out in this Privacy Policy, without the payment of any fees. We may, to the extent permitted by law, also use, license, reproduce, distribute disclose, and aggregate, non-personally identifiable information that is derived through your use of the portal and you hereby provide consent for the same.
                 </li>
             </ol>
             <h3>3. Sharing Of Information.</h3>
             <p>We will not use User information for any purpose other than in connection with the portal. Cipehrcode will not rent, sell or share User information and will not disclose any of the User’s personally identifiable information to third parties, unless:</p>
              <ul>
                  <li>it is pursuant to obtaining the User’s permission;</li>
                  <li>it is in connection with the services being rendered through the portal;</li>
                  <li>it is to help investigate, prevent or take action regarding unlawful and illegal activities; suspected fraud, a potential threat to the safety or security of any person, violations of Ciphercode’s Terms& Conditions, or as a defense against legal claims;</li>
                  <li>it is a case of special circumstances such as compliance with court orders, requests/order, notices from legal authorities or law enforcement agencies compel us to make such disclosure; </li>
                  <li>it forms part of the information Ciphercode shares with advertisers on an aggregate basis.</li>
              </ul>
              <h3>4. Information security.</h3>
              <ol className="d">
                  <li> The information provided by the User is stored in access controlled facilities with restricted access. User Information transmitted over the internet is protected through the use of encryption, using the Secure Socket Layer (SSL) or equivalent protocols.</li>
                  <li>If a password is used to help protect User accounts and account information, it is the responsibility of the User to keep the password confidential. You have to ensure that You always log out, before sharing the device with a third party and it is advised that the User utilize a service to protect access to the User’s Device.</li>
                 <li>We shall use generally accepted industry standards to protect the User information submitted to Us, both during transmission and upon receipt. However, please be advised that no method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, even though We strive to use commercially acceptable means to protect User information, We cannot guarantee its absolute security and your use of the portal is at your sole risk and discretion. We also cannot warrant that such User information may not be misused in the event our safeguards and protocols are breached by a malicious third-party. Further, We are not liable to nor can we fully control the actions of other users with whom you may choose to share your information.</li>
                 <li>The collection, usage, and sharing of User information by Ciphercode shall be in compliance with the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 and other portallicable laws.</li>
              </ol>
              <h3>5. Procedure For Removing User Name from the Records.</h3>
              <ol className="d">
                  <li>f and when a User is desirous of having his/her name and other details removed from the records of Ciphercode, immediately upon receiving the User’s written request to that effect Ciphercode shall, subject to the terms hereof, remove and/delete all such information.</li>
                  <li>If the User no longer wishes to receive notifications about Ciphercode services, The User may change his/her notification preferences by contacting Ciphercode at info@ciphercode.co Ciphercode reserves the right to close the User account if the User opts out of receiving certain crucial notices that are required to perform the Ciphercode services through its portal. The User may not opt-out of receiving notifications about due or past due amounts that the User owes Ciphercode or any other collections efforts.</li>
              </ol>
              <h3>6. Procedure For Correcting Inaccuracies In The Information.</h3>
              <p>The User may correct or update any information online. In the event of loss of access details the User may retrieve the same or receive new access details by sending an e-mail to: info@ciphercode.co</p>
              <h3>7. Grievance Officer.</h3>
              <p>In accordance with Information Technology Act 2000 and rules made thereunder, the name and contact details of our Grievance Officer are provided below:</p>
              <h6>NAME :	Terugu Venkat</h6>
              <h6>EMAIL : info@ciphercode.co</h6>
              <h6>Address :	No.199/1, 22nd Main HSR Layout, Sector 1 Bangalore – 560102 Karnataka, India</h6>
        </div>
        </div>
        </>
    )

}
export default Termscondition;