import React from 'react';
import img1 from "../../images/footwearimg1.webp";
import img2 from "../../images/Footwear 7.webp";
import img3 from "../../images/footwearimg3.webp";
import './utilise.css';

export default function Package() {
  return (
    <div className="container utilise-content" id="id_utilise_content">
      <h2>
        How the Footwear Industry leverages <span>Brandwise</span> . . .
      </h2>
      <div className="row g-4">
        {/* Card 1 */}
        <div className="col-md-4">
          <div className="card custom-card-footwear">
            <img
              src={img1}
              alt="Women going on a shopping spree excited for more experiences, offers and sales"
              loading="lazy"
              className="card-img-top"
              title="Customer enjoying exciting shopping experiences and offers."
            />
            <div className="card-body">
              <h3 className="card-title">Drive Store Footfall to New Heights</h3>
              <p className="card-text">
                Utilize proven 16+ dynamic engagement channels to boost store
                footfall and enhance conversion rates. Seamlessly integrate
                offline-to-online (O2O), bring diverse visitors from wider
                platforms. Deliver and accept omnichannel coupons, measure
                growth performance.
              </p>
            </div>
          </div>
        </div>

        {/* Card 2 */}
        <div className="col-md-4">
          <div className="card custom-card-footwear">
            <img
              src={img2}
              alt="Customer enjoying seamless omnichannel experiences, empowering brands to build strong connections and drive loyalty across platforms"
              loading="lazy"
              className="card-img-top"
              title="Seamless omnichannel experiences driving customer loyalty."
            />
            <div className="card-body">
              <h3 className="card-title">One Stop Platform for All Needs</h3>
              <p className="card-text">
                Brandwise not only has a rich set of tools, but it also comes
                with pre-loaded ecosystem integrations such as POS, Shopify,
                CRM, WhatsApp, Meta campaigns, and more. The unified platform
                relieves you from pinpoints & lets you focus on your core
                business.
              </p>
            </div>
          </div>
        </div>

        {/* Card 3 */}
        <div className="col-md-4">
          <div className="card custom-card-footwear">
            <img
              src={img3}
              alt="Business executive excited about tracking down and addressing counterfeit products, safeguarding his brand from loss of revenue."
              loading="lazy"
              className="card-img-top"
              title="Executive safeguarding brand by tackling counterfeit products."
            />
            <div className="card-body">
              <h3 className="card-title">Counterfeit Protection</h3>
              <p className="card-text">
                Utilize advanced product authentication to safeguard your brand
                and customers against counterfeit products. Ensure the integrity
                and trustworthiness of your products and avoid revenue losses.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="feedbackContent"></div><br /><br /> <br />
    </div>
  );
}
