import React from "react";
import img from "../../images/coverphoto.png";
import "./topHead.css";

function TopHead() {
	return (
		<div className="pt-3" style={{background: "linear-gradient(0.63deg, rgba(49, 87, 239, 0.1) 1.58%, rgba(49, 87, 239, 0) 97.39%)"}}>
			<div className="container blogpost">
				<div className="px-magic text-center text-md-start">
					<h1>NAVIGATING THE COOKIE-LESS FUTURE<hr className="solid" style={{margin:"0"}}></hr></h1> 
					<h2>Harnessing the Power of First Party Data</h2>
					<p>16 February 2024 | By Rohaan Joseph</p>
				</div>
			</div>
			<img className="cover-img" src={img} alt="cover_pic" />
		</div>
	);
}

export default TopHead;
